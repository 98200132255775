import React, { useContext } from "react";
import { GlobalContext } from "../../context/global";
import "./kategorije.css";

export default function Kategorije({ showObaveznoPolje }) {
  const { globalState, kliknutaKategorija } = useContext(GlobalContext);
  const { kategorija } = globalState;

  const categories = [
    { name: "Automobil", imgSrc: "/images/autoSelect.png" },

    { name: "Motor", imgSrc: "/images/motorSelect.png" },

    {
      name: "Kamion",
      imgSrc: "/images/kamionSelect.png",
    },

    { name: "Prikolica", imgSrc: "/images/prikolicaSelect.png" },
    { name: "Rent", imgSrc: "/icons/selectRent.svg", label: "Rent a car" },
    { name: "Plovilo", imgSrc: "/images/jahtaSelect.png" },
    { name: "Autobus", imgSrc: "/images/busSelect.png" },
    {
      name: "Masina",
      imgSrc: "/images/masineSelect.png",
      label: "Građevinska mašina",
    },
    {
      name: "Poljoprivreda",
      imgSrc: "/images/traktorSelect.png",
      label: "Poljoprivredna mašina",
    },

    {
      name: "Tocak",
      imgSrc: "/icons/felneSaGumama.png",
      label: "Felne / gume",
    },
  ];

  const handleSelectChange = (e) => {
    kliknutaKategorija(e.target.value);
  };

  return (
    <>
      <div className="kategorijeContainer">
        <p className="kategorijePcP">Izaberite kategoriju</p>
        <div className="kategorijePc">
          {categories.map((category) => (
            <div
              key={category.name}
              className={kategorija === category.name ? "filterKategorija" : ""}
              onClick={() => kliknutaKategorija(category.name)}
            >
              <img className="kategorijePcImg" src={category.imgSrc} />
              <p>{category.label || category.name}</p>
            </div>
          ))}
          <p className="hide">Obavezno polje!</p>
        </div>
        <div className="kategorije">
          <p className="obaveznoPolje">*</p>
          <div className="kategorijaDiv">
            <select
              className="kategorijaSelect"
              onChange={handleSelectChange}
              defaultValue=""
            >
              <option value="" disabled hidden>
                Kategorija
              </option>
              {categories.map((category) => (
                <option key={category.name} value={category.name}>
                  {category.label || category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
