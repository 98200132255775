import React, { useState } from "react";
import "./nacinPlacanja.css";
import { Link } from "react-router-dom";
import Menu from "../menu/Menu";

export default function NacinPlacanja({ dodajNacinPlacanja,nacinPlacanja, link ,platiKarticom}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [checkedIndex, setCheckedIndex] = useState(null);
  const handleCheckboxChange = (index) => {
    setCheckedIndex(index);
  };

  const handleImageUpload = (event) => {
    const imageFile = event.target.files[0];
    const imageUrl = URL.createObjectURL(imageFile);
    setSelectedImage(imageUrl);
  };

  const handleSendMessage = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
      window.location.href = "/"; 
    }, 3000);
  };


 
  const nazivPlacanja = platiKarticom ? platiKarticom : "Dodaj način plaćanja";



  const uplatniceData = {
    naslov: "Plati u banci ili pošti",
    stavke: [
      { label: "Iznos za uplatu:", value: "5 €" },
      { label: "Svrha uplate:", value: "Reklama oglasa" },
      { label: "Naziv korisnika:", value: "DOO IKRA WEB" },
      { label: "Hipotekarna banka:", value: "684684681" }
    ]
  };
  return (
    <div className="nacinPlacanja">
          <Menu
         homeMenu="/icons/homee.svg"
         likedMenu="/icons/heart.png"
         addPostMenu="/icons/dodajOglas.svg"
         porukeMenu="/icons/poruke.svg"
         profilMenu="/icons/profileSelect.png"
      />
      <div className="headerAktivni">
        <Link className="back" to={link}>
          <img src="/icons/back.png" className="backBtn" alt="Back" />
        </Link>
        <img className="logo" src="/icons/logo.png" />
        <h1 className="naslovAktivniOglasi">{nacinPlacanja}</h1>
      </div>
      <div className="nacinSacuvaneKartice" >
        <div className="sacuvanaKartica" onClick={() => handleCheckboxChange(0)}>
         <div> <img src="/icons/visa.png"/>
          <p>Tvoja kartica (...1567)</p>
          </div>
          <div className="checkbox-wrapper-15">
          <input
            className="inp-cbx"
            id="cbx-15"
            type="checkbox"
            style={{ display: "none" }}
            checked={checkedIndex === 0}
            onChange={() => handleCheckboxChange(0)}
          />
          <label className="cbx" htmlFor="cbx-15">
            <span>
              <svg width="12px" height="9px" viewBox="0 0 12 9">
                <polyline points="1 5 4 8 11 1"></polyline>
              </svg>
            </span>
          </label>
        </div>
        </div>
        
        <div className="sacuvanaKartica" onClick={() => handleCheckboxChange(1)}>
          <div>
          <img src="/icons/visa.png"/>
          <p>Tvoja kartica (...1567)</p>
          </div>
          <div className="checkbox-wrapper-12">
          <input
            className="inp-cbx3"
            id="cbx-12"
            type="checkbox"
            style={{ display: "none" }}
            checked={checkedIndex === 1}
            onChange={() => handleCheckboxChange(1)}
          />
          <label className="cbx3" htmlFor="cbx-12">
            <span>
              <svg width="12px" height="9px" viewBox="0 0 12 9">
                <polyline points="1 5 4 8 11 1"></polyline>
              </svg>
            </span>
          </label>
        </div>
        </div>
      </div>
        <div className="dodajNoviDiv">
          <div className="linija"></div>
          <p className="pDodajNovi">Dodaj novi</p>
          <div className="linija"></div>
        </div>
        <div className="karticeUplatnice">
      <div className={selectedImage ? "hide" : "karticeChoose"}>
        <div>
          <div className="">
            <img src="/icons/mastercard.png" />
            <p>Mastercard</p>
          </div>
          <p className="razmak">/</p>
          <div>
            <img src="/icons/visa.png" />
            <p>Visa</p>
          </div>
        </div>
        <Link to="kartice" className="platiKarticom">{nazivPlacanja}</Link>
      </div>
{dodajNacinPlacanja&&
    <div className="uplatnice">
    <p className="uplatniceNaslov">{uplatniceData.naslov}</p>
    <div>
      {uplatniceData.stavke.map((stavka, index) => (
        <div key={index}>
          <p>{stavka.label}</p>
          <p>{stavka.value}</p>
        </div>
      ))}
    </div>
    <label htmlFor="uploadInput" className="slikajUplatnicu">
      Slikaj uplatnicu
    </label>
    <input
      type="file"
      id="uploadInput"
      accept="image/*"
      style={{ display: "none" }}
      onChange={handleImageUpload}
    />
  </div>}</div>

      <div className="slikaUplatnice">
        {selectedImage && (
          <>
            <img className="imgUplatnica" src={selectedImage} alt="Uplatnica" />
            <button className="posaljiUplatnicu" onClick={handleSendMessage}>Pošalji oglas</button>
          </>
        )}
      </div>

     
      {showSuccessMessage && (
          <div className="successMessage">
          <div>
            <div>
          <img className="allDone" src="/images/allDone.png"/>
            
            <img className="vector" src="/images/vector.png"/>
          </div><p>Uspješno ste poslali oglas!</p>
            </div>
          </div>
      )}
     
    </div>
  );
}
