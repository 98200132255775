import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./uporedjivanjeOglasa.css";

import HeaderPhone from "../../components/headerPhone/HeaderPhone";
import Menu from "../../components/menu/Menu";
import TabBar from "../../components/Tabbar/Tabbar";

import { useContext } from "react";
import { GlobalContext } from "../../context/global";
export default function UporedjivanjeOglasa() {
  const { globalState: { filterSearch } = {}, setGlobalState } =
    useContext(GlobalContext);
  const da = true;
  const [kmPrvo, setKmPrvo] = "";
  const [kmDrugo, setKmDrugo] = "";
  const dodajKlase = (prvaVrednost, drugaVrednost) => {
    if (prvaVrednost < drugaVrednost) {
      return {
        prvoVoziloKlasa: "slabijePrvo",
        drugoVoziloKlasa: "jaceDrugo",
      };
    } else if (prvaVrednost > drugaVrednost) {
      return {
        prvoVoziloKlasa: "jacePrvo",
        drugoVoziloKlasa: "slabijeDrugo",
      };
    } else {
      return {
        prvoVoziloKlasa: "neutralnoPrvo",
        drugoVoziloKlasa: "neutralnoDrugo",
      };
    }
  };

  const godiste = {
    prvoVozilo: 2010,
    drugoVozilo: 2007,
  };

  const zapremina = {
    prvoVozilo: 2000,
    drugoVozilo: 2000,
  };

  const snaga = {
    prvoVozilo: 140,
    drugoVozilo: 130,
  };
  const cijena = {
    prvoVozilo: 9177,
    drugoVozilo: 7845,
  };
  const { prvoVoziloKlasa: cijenaPrvo, drugoVoziloKlasa: cijenaDrugo } =
    dodajKlase(cijena.prvoVozilo, cijena.drugoVozilo);

  const { prvoVoziloKlasa: godistePrvo, drugoVoziloKlasa: godisteDrugo } =
    dodajKlase(godiste.prvoVozilo, godiste.drugoVozilo);

  const { prvoVoziloKlasa: zapreminaPrvo, drugoVoziloKlasa: zapreminaDrugo } =
    dodajKlase(zapremina.prvoVozilo, zapremina.drugoVozilo);

  const { prvoVoziloKlasa: snagaPrvo, drugoVoziloKlasa: snagaDrugo } =
    dodajKlase(snaga.prvoVozilo, snaga.drugoVozilo);
  const prvoVoziloCijena = 7858;
  const drugoVoziloCijena = 4278;
  const cijenaPrvoVece = prvoVoziloCijena > drugoVoziloCijena;

  const prvoVoziloKm = 250000;
  const drugoVoziloKm = 307278;

  const kmPrvoVece = prvoVoziloKm > drugoVoziloKm;
  const vozila = {
    vozilo1: {
      marka: "Volkswagen",
      model: "CC",
      gorivo: "Dizel",
      karoserija: "Limuzina",

      mjenjac: "Manual",
      broj: "+234234234",
      boja: "Crna",
      ostecenje: "Nema",
      tablice: "Domaće",
      registrovanDo: "12/2024",
      zamjena: "Da",
      opis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt massa at felis ullamcorper convallis. Nullam nec est sit amet sem ultricies suscipit. Integer in justo et mauris tempor ullamcorper.",
      dodatnaOprema: [
        "dsfdsds dffds ff",
        "ds sdffdssd ff",
        "dsdf sdfsdfsfdsf",
        "dsfdsdsf sf",
        "dsfdsf",
        "dsfdssdff",
        "dsfdsdsff",
        "dsfdssdff",
        "dsdsfsdfdsf",
      ],
    },
    vozilo2: {
      marka: "Mercedes",
      model: "A220",
      gorivo: "Benzin",
      karoserija: "Hečbek",

      mjenjac: "Automatik",
      broj: "+65525282",
      boja: "Siva",
      ostecenje: "Ima",
      tablice: "Strane",
      registrovanDo: "2/2025",
      zamjena: "Ne",
      opis: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt massa at felis ullamcorper convallis. Nullam nec est sit amet sem ultricies suscipit. Integer in justo et mauris tempor ullamcorper. sdf sdf dsdf sd fdsf",
      dodatnaOprema: [
        "dsfdsds dffds ff",
        "ds sdffdssd ff",
        "dsdf sdfsdfsfdsf",
        "dsfdsdsf sf",
        "dsfdsf",
        "dsfdssdff",
        "dsfdsdsff",
        "dsfdssdff",
        "dsdsfsdfdsf",
      ],
    },
  };
  const dodatnaOpremaPodaci = {
    vozilo1: [
      "dsfdsds dffds ff",
      "ds sdffdssd ff",
      "dsdf sdfsdfsfdsf",
      "dsfdsdsf sf",
      "dsfdsf",
      "dsfdssdff",
      "dsfdsdsff",
      "dsfdssdff",
      "dsdsfsdfdsf",
    ],
    vozilo2: [
      "dsfdsds dffds ff",
      "ds sdffdssd ff",
      "dsdf sdfsdfsfdsf",
      "dsfdsdsf sf",
      "dsfdsf",
      "dsfdssdff",
      "dsfdsdsff",
      "dsfdssdff",
      "dsdsfsdfdsf",
    ],
  };

  return (
    <div className="uporedjivanjeVozila">
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglass.png"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/podesavanja.svg"
      />
      <HeaderPhone />
      <div className="uporedjivanjeVozilaDiv">
        <div className="slika">
          <img className="slikaUporedi" src="/images/1.jpg" />{" "}
          <p className="slikaUporediP">vs</p>{" "}
          <img className="slikaUporedi" src="/images/2.jpg" />
        </div>
        <div className="markaModelUporedi">
          <p className="prvoVozilo">Volkswagen, cc</p>
          <p className="stavkeUporedi">Naslov</p>
          <p className="drugoVozilo">Mercedes, b200</p>
        </div>
        <div className="markaModelUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.marka}</p>
          <p className="stavkeUporedi">Marka</p>
          <p className="drugoVozilo">{vozila.vozilo2.marka}</p>
        </div>
        <div className="cijenaUporedi">
          <p
            className={`prvoVozilocijena ${
              cijenaPrvoVece ? "slabijePrvo" : "jacePrvo"
            }`}
          >
            {prvoVoziloCijena} €
          </p>
          <p className="stavkeUporedi">Cijena</p>
          <p
            className={`drugoVozilocijena ${
              cijenaPrvoVece ? "jaceDrugo" : "slabijeDrugo"
            }`}
          >
            {drugoVoziloCijena} €
          </p>
        </div>
        <div className="markaModelUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.model}</p>
          <p className="stavkeUporedi">Model</p>
          <p className="drugoVozilo">{vozila.vozilo2.model}</p>
        </div>
        <div className="godisteUporedi">
          <p className={`prvoVozilo ${godistePrvo}`}>{godiste.prvoVozilo}</p>
          <p className="stavkeUporedi">Godište</p>
          <p className={`drugoVozilo ${godisteDrugo}`}>{godiste.drugoVozilo}</p>
        </div>
        <div className="gorivoUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.gorivo}</p>
          <p className="stavkeUporedi">Gorivo</p>
          <p className="drugoVozilo">{vozila.vozilo2.gorivo}</p>
        </div>
        <div className="zapreminaUporedi">
          <p className={`prvoVozilo ${zapreminaPrvo}`}>
            {zapremina.prvoVozilo} cm3
          </p>
          <p className="stavkeUporedi">Zapremina</p>
          <p className={`drugoVozilo ${zapreminaDrugo}`}>
            {zapremina.drugoVozilo} cm3
          </p>
        </div>
        <div className="karoserijaUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.karoserija}</p>
          <p className="stavkeUporedi">Karoserija</p>
          <p className="drugoVozilo">{vozila.vozilo2.karoserija}</p>
        </div>
        <div className="ksUporedi">
          <p className={`prvoVozilo ${snagaPrvo}`}>{snaga.prvoVozilo} ks</p>
          <p className="stavkeUporedi">Snaga</p>
          <p className={`drugoVozilo ${snagaDrugo}`}>{snaga.drugoVozilo} ks</p>
        </div>
        <div className="mjenjacUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.mjenjac}</p>
          <p className="stavkeUporedi">Mjenjač</p>
          <p className="drugoVozilo">{vozila.vozilo2.mjenjac}</p>
        </div>
        <div className="kmUporedi">
          <p
            className={`prvoVozilokm ${
              kmPrvoVece ? "slabijePrvo" : "jacePrvo"
            }`}
          >
            {prvoVoziloKm}
          </p>
          <p className="stavkeUporedi">Kilometraža</p>
          <p
            className={`drugoVozilokm ${
              kmPrvoVece ? "jaceDrugo" : "slabijeDrugo"
            }`}
          >
            {drugoVoziloKm}
          </p>
        </div>

        <div className="bojaUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.boja}</p>
          <p className="stavkeUporedi">Boja</p>
          <p className="drugoVozilo">{vozila.vozilo2.boja}</p>
        </div>
        <div className="ostecenjeUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.ostecenje}</p>
          <p className="stavkeUporedi">Oštecenje</p>
          <p className="drugoVozilo">{vozila.vozilo2.ostecenje}</p>
        </div>
        <div className="tabliceUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.tablice}</p>
          <p className="stavkeUporedi">Tablice</p>
          <p className="drugoVozilo">{vozila.vozilo2.tablice}</p>
        </div>
        <div className="regUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.registrovanDo}</p>
          <p className="stavkeUporedi">Registrovan do</p>
          <p className="drugoVozilo">{vozila.vozilo2.registrovanDo}</p>
        </div>
        <div className="zamjenaUporedi">
          <p className="prvoVozilo">{vozila.vozilo1.zamjena}</p>
          <p className="stavkeUporedi">Zamjena</p>
          <p className="drugoVozilo">{vozila.vozilo2.zamjena}</p>
        </div>
      </div>

      <div className="dodatneInformacijeUporedi">
        <p className="dodatnaOpremaUporedi">Dodatna oprema</p>
        <div className="dodatnaOpremaDiv">
          <div className="opremaVozilo1">
            {dodatnaOpremaPodaci.vozilo1.map((oprema, index) => (
              <div key={index} className="opremaItem">
                <p>{oprema}</p>
                <img
                  src="/icons/correct.png"
                  alt="Correct"
                  className="correct"
                />
              </div>
            ))}
          </div>
          <div className="opremaVozilo2">
            {dodatnaOpremaPodaci.vozilo2.map((oprema, index) => (
              <div key={index} className="opremaItem">
                <img
                  src="/icons/correct.png"
                  alt="Correct"
                  className="correct"
                />
                <p>{oprema}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="opisUporedi">
        <p className="naslovOpisUporedi">Opis</p>
        <div className="opisUporediDiv">
          <div className="opisUporedi1">
            <p>{vozila.vozilo1.opis}</p>
          </div>
          <div className="opisUporedi2">
            <p>{vozila.vozilo2.opis}</p>
          </div>
        </div>
      </div>
      <div className="kontaktUporedi">
        <div className="relative">
          <div>
            <img className="userPhotoUporedi" src="/images/pic.jpg" />
            <p className="imeUporedi">Dino</p>
          </div>
          <div className="prosjecnaOcjena ">
            <p className="prosjecnaOcjenaPUporedi">4.5</p>
            <img className="averStarUporedi" src="/icons/star.png" />
          </div>
          <div className="hrUporedi"></div>
          <div>
            <img src="/icons/location.png" className="lokacijaIcons" />
            <p className="lokacijaPAds">Crna Gora, Bijelo Polje</p>
          </div>
          <div className="hrUporedi"></div>{" "}
          <a href="tel:+58578584" className="brojUporedi">
            {vozila.vozilo1.broj}
          </a>
        </div>
        <div className="borderUporedi"></div>
        <div className="relative">
          <div>
            <img className="userPhotoUporedi" src="/images/pic.jpg" />
            <p className="imeUporedi">Dino</p>
          </div>
          <div className="prosjecnaOcjena">
            <p className="prosjecnaOcjenaPUporedi">4.5</p>
            <img className="averStarUporedi" src="/icons/star.png" />
          </div>
          <div className="hrUporedi"></div>
          <div>
            <img src="/icons/location.png" className="lokacijaIcons" />
            <p className="lokacijaPAds">Crna Gora, Bijelo Polje</p>
          </div>
          <div className="hrUporedi"></div>
          <a href="tel:+58578584" className="brojUporedi">
            {vozila.vozilo2.broj}
          </a>
        </div>
      </div>
      <div className={filterSearch ? "hide" : "sdfsf"}>
        <TabBar
          home="/icons/home.svg"
          heart="/icons/heart.png"
          dodajOglas="/icons/dodajOglas.svg"
          poruke="/icons/poruke.svg"
          profil="/icons/podesavanja.svg"
        />
      </div>
    </div>
  );
}
