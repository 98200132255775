import React, { useState } from "react";
import "./pretragaProfil.css";
import { Link } from "react-router-dom";
import KategorijePretraga from "../kategorijePretraga/KategorijePretraga";

export default function PretragaProfil() {
  const [selectedCategory, setSelectedCategory] = useState(null);

 
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="kategorijePocetna">
      <h1 className="sveKategorije">Sve kategorije</h1>
      <KategorijePretraga
      pocetna={true}
        pocetnaProfil={true}
        uslov={true}

        auto={selectedCategory === "auto" ? "/images/autoSelected.png" : "/images/autoSelect.png"}
        motor={selectedCategory === "motor" ? "/images/motorSelected.png" : "/images/motorSelect.png"}
        rent={selectedCategory === "rent" ? "/icons/selectedRent.svg" : "/icons/selectRent.svg"}
        bus={selectedCategory === "bus" ? "/images/busSelected.png" : "/images/busSelect.png"}
        kamion={selectedCategory === "kamion" ? "/images/kamionSelected.png" : "/images/kamionSelect.png"}
        prikolica={selectedCategory === "prikolica" ? "/images/prikolicaSelected.png" : "/images/prikolicaSelect.png"}
        jahta={selectedCategory === "jahta" ? "/images/jahtaSelected.png" : "/images/jahtaSelect.png"}
        masine={selectedCategory === "masine" ? "/images/masineSelected.png" : "/images/masineSelect.png"}
        traktor={selectedCategory === "traktor" ? "/images/traktorSelected.png" : "/images/traktorSelect.png"}
        tocak={selectedCategory === "tocak" ? "/images/tocakSelected.png" : "/images/tocakSelect.png"}



        styleKategorijeAuto={selectedCategory === "auto" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijeMotor={selectedCategory === "motor" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijeRent={selectedCategory === "rent" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijeBus={selectedCategory === "bus" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijeKamion={selectedCategory === "kamion" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijePrikolica={selectedCategory === "prikolica" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijeJahta={selectedCategory === "jahta" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijeMasine={selectedCategory === "asine" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijeTraktor={selectedCategory === "traktor" ? "selectKategorija selectedDiv" : "selectKategorija"}
        styleKategorijeTocak={selectedCategory === "tocak" ? "selectKategorija selectedDiv" : "selectKategorija"}


        LinkAuto="LinkBorderRight"
        LinkMotor="LinkBorderRight"
        LinkBus="LinkBorderRight"
        LinkKamion="LinkBorderRight"
        LinkPrikolica="LinkBorderRight"
        LinkJahta="LinkBorderRight"
        LinkMasine="LinkBorderRight"
        LinkTraktor="LinkBorderRight"
        LinkTocak="LinkBorderRight"

        LinkRent="LinkBorderRight"
      />
      </div>
  );
}
