import React from "react";
import Kod from "../kod/Kod";
import PocetnaLogin from "../../components/pocetnaLogin/PocetnaLogin";
export default function Login({ nemaNalog }) {
  return (
    <>
      <PocetnaLogin />
    </>
  );
}
