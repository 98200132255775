import React, { useState } from "react";

import { Link } from "react-router-dom";
import NacinPlacanja from "../../components/nacinPlacanja/NacinPlacanja";

export default function DodajNacinPlacanja() {

  
  return (
    <div className="dodajNacinPlacanja">

   <NacinPlacanja link="/profil" nacinPlacanja="Dodaj način plaćanja"/>
    </div>
  );
}
