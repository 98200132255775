import { useEffect } from "react";
import React, { useState } from "react";
import Tabbar from "../Tabbar/Tabbar";
import { Link } from "react-router-dom";
import "./inbox.css";
import AutoFilter from "../autoFilter/AutoFilter";
import Poruka from "../../pages/poruka/Poruka";

export default function Inbox() {
  const [kliknuto, setKliknuto] = useState(true);
  const [searchKlik, setSearchKlik] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [hidePretrazi, setHidePretrazi] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const search = () => {
    setSearchKlik(!searchKlik);
  };

  const toggleInbox = () => {
    setKliknuto(!kliknuto);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const closeSearch = () => {
    setSearchValue("");
    setHidePretrazi(true);
  };

  const inbox = [
    {
      id: 1,
      name: "Dino",
      message:
        "Poz zadnja cijena? Poz zadnja cij sdfsdf sd fsdf sdf sd fsd sfdsdf",
      time: "upravo sada",
      profileImg: "/images/dino.jpg",
      seenImg: "/icons/seen.png",
    },
    {
      id: 2,
      name: "Asdfasdc",
      message: "3284 2984792387 498327 932847 32974 932874 93274 892374 9073",
      time: "prije 10 min",
      profileImg: "/images/3.jpg",
      novePoruke: "3",
    },
    {
      id: 3,
      name: "dfsdfdsf",
      message:
        "fds fsd sdf sdf sdf sd sd fsd df j sdfsdf sd fsdf sdf sd fsd sfdsdf",
      time: "Petak 21:15",
      profileImg: "/images/profilePhoto.jpg",
      novePoruke: "9+",
    },
    {
      id: 4,
      name: "dfsdfdsf",
      message: "43209 2384 908243289 sdfsdf sd fsdf sdf sd fsd sfdsdf",
      time: "Petak 21:15",
      profileImg: "/images/profilePhoto.jpg",
      seenImg: "/icons/seen.png",
    },
    {
      id: 5,
      name: "dfsdfdsfdsf",
      message: "f48 32908 2398409283 d df j sdfsdf sd fsdf sdf sd fsd sfdsdf",
      time: "Petak 21:15",
      profileImg: "/images/profilePhoto.jpg",
      seenImg: "/icons/sent.png",
    },
    {
      id: 6,
      name: "Anonim",
      message: "fds fsd sdf sdf?",
      time: "prije 2h",
      profileImg: "/images/profilePhoto.jpg",
      seenImg: "/icons/seen.png",
    },
    {
      id: 7,
      name: "dfsdfdsf",
      message: "Sdsof pmdsm pfdsčl fsčdl mlčsd mfčlmfds ",
      time: "Subota 1:15",
      profileImg: "/images/profilePhoto.jpg",
      seenImg: "/icons/sent.png",
    },
  ];

  useEffect(() => {
    const bodyColorPc = document.querySelector(".InboxPart");
    const body = bodyColorPc.closest("body");
    body.classList.add("bodyColor777");
  }, []);

  const poslatePoruke = inbox.filter(
    (message) => message.seenImg === "/icons/sent.png"
  );

  return (
    <div className="pcMess">
      <div className="relative InboxPart">
        <div className="headerDodajOglas">
          <p className="naslovDodajOglas">Poruke</p>
          <img
            className="dodajOglasNaslovKategorija"
            onClick={search}
            src="/icons/pretrazi.png"
          />
        </div>
        <div className={searchKlik ? "hidePretrazi" : "pretraziUser show"}>
          <input
            className="inputPretrazi"
            placeholder="Pretražite"
            onChange={handleInputChange}
            value={searchValue}
          />
          <img
            className="searchBtn"
            src={
              searchValue ? "/icons/bijeliSearch.png" : "/icons/closeSearch.png"
            }
            onClick={() => {
              closeSearch();
              search();
            }}
            alt="Send"
          />
        </div>
        <div className="vrstePoruka">
          <div
            className={kliknuto ? "clickedInbox" : "noClickedInbox"}
            onClick={toggleInbox}
          >
            Inbox
          </div>
          <div
            className={kliknuto ? "noClickedInbox" : "clickedInbox"}
            onClick={toggleInbox}
          >
            Poslato
          </div>
        </div>
        <div className={kliknuto ? "hide" : "poslato"}>
          {poslatePoruke.map((message) => (
            <div key={message.id}>
              <div className="inboxDiv">
                <div className="firstDivMess">
                  <div>
                    <img className="profilnaInbox" src={message.profileImg} />
                  </div>
                  <div className="imePorukaInboxDiv">
                    <p className="imeInbox">{message.name}</p>
                    <p className="porukaInbox">{message.message}</p>
                  </div>
                </div>
                <div className="vrijemeSeenDiv">
                  <p className="vrijemePoruke">{message.time}</p>
                  {message.seenImg ? (
                    <img className="seen" src={message.seenImg} />
                  ) : (
                    <span>{message.novePoruke}</span>
                  )}
                </div>
              </div>
              <div className="hr"></div>
            </div>
          ))}
        </div>
        <div className={kliknuto ? "inboxContainer" : "hide"}>
          {inbox.map((message) => (
            <div key={message.id}>
              {windowWidth > 777 ? (
                <div className="inboxDiv">
                  <div className="firstDivMess">
                    <div>
                      <img className="profilnaInbox" src={message.profileImg} />
                    </div>
                    <div className="imePorukaInboxDiv">
                      <p className="imeInbox">{message.name}</p>
                      {message.novePoruke ? (
                        <p className="porukaInboxBold">{message.message}</p>
                      ) : (
                        <p className="porukaInbox">{message.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="vrijemeSeenDiv">
                    <p className="vrijemePoruke">{message.time}</p>
                    {message.seenImg ? (
                      <img className="seen" src={message.seenImg} />
                    ) : (
                      <span className="brojNovihPoruka">
                        {message.novePoruke}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <Link to="/poruka" className="inboxDiv">
                  <div className="firstDivMess">
                    <div>
                      <img className="profilnaInbox" src={message.profileImg} />
                    </div>
                    <div className="imePorukaInboxDiv">
                      <p className="imeInbox">{message.name}</p>
                      {message.novePoruke ? (
                        <p className="porukaInboxBold">{message.message}</p>
                      ) : (
                        <p className="porukaInbox">{message.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="vrijemeSeenDiv">
                    <p className="vrijemePoruke">{message.time}</p>
                    {message.seenImg ? (
                      <img className="seen" src={message.seenImg} />
                    ) : (
                      <span className="brojNovihPoruka">
                        {message.novePoruke}
                      </span>
                    )}
                  </div>
                </Link>
              )}
              <div className="hr"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="pcOnlyMess">
        <Poruka />
      </div>
    </div>
  );
}
