import "./upload.css";
import React, { useState } from "react";

export default function KategoUploadije() {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagesUploaded, setImagesUploaded] = useState(false);

  const handleImageUpload = (e) => {
    const files = e.target.files;
    const selectedImagesArray = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );

    if (selectedImagesArray.length + selectedImages.length > 15) {
      alert("Možete dodati najviše 15 fotografija i 1 video snimak.");
      return;
    }

    setSelectedImages((prevImages) => [...prevImages, ...selectedImagesArray]);
    setImagesUploaded(true);
  };

  const handleImageDrag = (index, e) => {
    e.dataTransfer.setData("index", index.toString());
  };

  const handleImageDrop = (index, e) => {
    const dragIndex = parseInt(e.dataTransfer.getData("index"));
    const updatedImages = [...selectedImages];
    const movedImage = updatedImages.splice(dragIndex, 1)[0];
    updatedImages.splice(index, 0, movedImage);
    setSelectedImages(updatedImages);
  };

  return (
    <>
      <div className="uploadPhoto">
        <div className={imagesUploaded ? "" : "divUpload"}>
          <label
            htmlFor="imageUpload"
            className={imagesUploaded ? "hide" : "uploadLabel"}
          >
            <img className="uploadIcons" src="/icons/upload.svg" />
            <p className="dodajFotografijeP">Dodaj fotografije</p>
          </label>
          <input
            id="imageUpload"
            type="file"
            accept="image/*, video/*"
            multiple
            onChange={handleImageUpload}
            style={{ display: "none" }}
          />
          <div
            className={`uploadedPhoto ${imagesUploaded ? "activeUpload" : ""}`}
          >
            <h2 className="naslovSlikeH2">Dodate fotografije</h2>
            <div className="divUploadovanihSlika">
              {selectedImages.map((image, index) => (
                <div key={index} className="redosledImg">
                  <p className="brojRedosleda">{index + 1}.</p>
                  <img
                    className="uploadovaneSlike"
                    src={image}
                    alt={`Uploaded ${index}`}
                    onDragStart={(e) => handleImageDrag(index, e)}
                    onDrop={(e) => handleImageDrop(index, e)}
                    onDragOver={(e) => e.preventDefault()}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="objasnjenjeDivUpload">
          <p className="objasnjenjePDodajOglas ispod800">
            Redosled slika na oglasu zavisi od toga koju ste prvu sliku označili
            prilikom dodavanja fotografija.
          </p>
          <p
            className={
              imagesUploaded ? "hide" : "objasnjenjePDodajOglas preko800"
            }
          >
            Možete dodati najviše 15 fotografija i 1 video snimak.
          </p>
          <p
            className={
              imagesUploaded ? "objasnjenjePDodajOglas preko800" : "hide"
            }
          >
            Podesite redosljed slika prevlačenjem.
          </p>
        </div>
      </div>
    </>
  );
}
