import React from "react";
import LikedPost from "../../components/likedPost/LikedPost";

export default function SacuvaniOglasi() {
  return (
    <>
      <LikedPost showFavorited={true} naslov="Sačuvani oglasi" />
    </>
  );
}
