import React from "react";

import KarticaPodaci from "../../components/karticaPodaci/KarticaPodaci";

export default function KarticaPodaciProfil() {
  return (
    <div className="karticaPodaciOglas">
      <KarticaPodaci
        backTo="/profil/dodaj-nacin-placanja"
        profilKartica="true"
      />
    </div>
  );
}
