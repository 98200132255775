import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./autoFilter.css";
import DodatnaOprema from "../dodatnaOprema/DodatnaOprema";
export default function AutoFilter({
  hideElement,
  rasponRupa,
  tipVozila,
  hideBus,
  hidePrikolica,
  konjskeSnage,
  materijalFelne,
  velicina,
  kilovati,
  brVrata,
  kubikaza,
  mjenjac,
  materijalSelect,
  sirinaOdDo,
  duzinaOdDo,
  tipHladjenja,
  drzava,
  grad,
  pogon,
  brCilindara,
  boja,
  registracija,
  styleOdDo,
  brSati,
  brojVrata,
  godiste,
  kubikazaOdDo,
  zamjena,
  duzinaSirina,
  kilometraza,
  cijena,
  komada,
  sezona,
  sirinaGume,
  visina,
  promjer,
  modelInput,
  karoserija,
  nosivost,
  modelSelect,
  felne,
  oznaka,
  sirina,
  felneSaGumama,
  usluge,
  error,
  cm3DodajOglas,
  dodajOglasDrzavaGrad,
  cijenaDodajOglas,
  obavezniPodatak,
  kmDodajOglas,
  kategorija,
  gume,
  ksDodajOglas,
  kwDodajOglas,
  duzina,
  srcDodajOglasIkonica,
  markaSelect,
  kategorijaNaslovaDodajOglas,
  markaInput,
  gorivo,
  dodajOglas,
  godisteDodajOglas,
  tip,
  showObaveznoPolje,
  tablice,
  brOsovina,
  brSatiOdDo,
  brMotora,
  ostecenje,
  brSjedista,
  stanje,
  brBrzinaMjenjaca,
  backDodajOglas,
  kategorijeFelneGume,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(event.target.value);
  };
  return (
    <>
      {dodajOglas && (
        <div className="headerDodajOglas">
          <Link onClick={backDodajOglas} className="back" to="/dodaj-oglas">
            <img src="/icons/back.png" className="backBtn" alt="Back" />
          </Link>
          <p className="naslovDodajOglas">{kategorijaNaslovaDodajOglas}</p>
          <img
            className="dodajOglasNaslovKategorija"
            src={srcDodajOglasIkonica}
          />
        </div>
      )}
      {dodajOglas && (
        <div className="h1DivDodajOglas">
          <div>
            <h1 className="h1PopuniDetalje">Popunite detalje</h1>
            <div className="objasnjenjeDiv">
              <p className="red">*</p>
              <p className="objasnjenjeP">- obavezno polje</p>
            </div>
          </div>
          <p className="koraci">2/3</p>
        </div>
      )}
      {error && (
        <div className="relative">
          {usluge && (
            <select className="uslugeSelect error">
              <option disabled selected>
                Usluge
              </option>
            </select>
          )}
          <p className="errorMess">Obavezno polje</p>
        </div>
      )}
      {showObaveznoPolje && (
        <p className="osnovneInformacije">1. Osnovne informacije</p>
      )}
      {kategorijeFelneGume && (
        <div className="relative">
          {" "}
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select
            className="nosivostSelect"
            value={selectedOption}
            onChange={handleChange}
          >
            <option value="" disabled selected hidden>
              Odaberite kategoriju
            </option>
            <option value="felne">Felne</option>
            <option value="gume">Gume</option>
            <option value="felneGume">Felne sa gumama</option>
          </select>
        </div>
      )}
      {showObaveznoPolje && (
        <div className="naslovContainer relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <input className="naslovInput" placeholder="Naslov oglasa"></input>
        </div>
      )}
      {tip && (
        <div className="relative">
          {" "}
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="tipSelect">
            <option disabled selected>
              Tip
            </option>
          </select>
        </div>
      )}
      {kategorija && (
        <div className="relative">
          <select className="kategorijaSelect">
            <option disabled selected>
              Kategorija
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {markaInput && (
        <div className="relative">
          {" "}
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <p className="hide">Obavezno polje</p>{" "}
          <input className="inputMarka" placeholder="Marka" />
        </div>
      )}
      {markaSelect && (
        <div className="relative">
          <select className="markaSelect">
            <option disabled selected>
              Marka
            </option>
          </select>{" "}
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {modelSelect && (
        <div className="relative">
          <div className="relative">
            {obavezniPodatak && <p className="obavezniPodatak">*</p>}
            <select className="modelSelect">
              <option selected disabled>
                Model
              </option>
            </select>

            <p className="hide">Obavezno polje</p>
          </div>
        </div>
      )}
      {modelInput && (
        <div className=" relative">
          {" "}
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <input className="oznakaInput" placeholder="Model"></input>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {oznaka && (
        <div className="oznakaContainer relative">
          <input
            className="oznakaInput"
            placeholder="Oznaka: tdi, gti, tfsi.."
          ></input>
        </div>
      )}
      {nosivost && (
        <div className="relative">
          {" "}
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Nosivost
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}{" "}
      {felneSaGumama && (
        <>
          {selectedOption === "felneGume" && (
            <>
              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Veličina
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Širina gume
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Visina gume
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Raspon rupa
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Komada
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>
            </>
          )}
        </>
      )}
      {felne && (
        <>
          {selectedOption === "felne" && (
            <>
              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Tip vozila
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>
              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Materijal
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Veličina
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Raspon rupa
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>
              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Komada
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>
            </>
          )}
        </>
      )}
      {gume && (
        <>
          {selectedOption === "gume" && (
            <>
              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Tip vozila
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>
              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Širina
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Visina
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Sezona
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>

              <div className="relative">
                {obavezniPodatak && <p className="obavezniPodatak">*</p>}
                <select className="nosivostSelect">
                  <option disabled selected>
                    Komada
                  </option>
                </select>
                <p className="hide">Obavezno polje</p>
              </div>
            </>
          )}
        </>
      )}
      {karoserija && (
        <div className="karoserijaContainer relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="karoserijaSelect">
            <option disabled selected>
              Karoserija
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {gorivo && (
        <div className="gorivoContainer relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="gorivoSelect">
            <option selected disabled>
              Gorivo
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {godisteDodajOglas && (
        <div className="godisteContainer relative">
          <div className="relative">
            <p className="obavezniPodatak">*</p>
            <select className="godisteSelect">
              <option selected disabled>
                Godište
              </option>
            </select>

            <p className="hide">Obavezno polje</p>
          </div>
        </div>
      )}
      {kmDodajOglas && (
        <div className="kmContainer relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <input className="kmInput" placeholder="Kilometraža"></input>
          <p className="kmP">km</p> <p className="hide">Obavezno polje</p>
        </div>
      )}
      {cm3DodajOglas && (
        <div className="cm3Container marginNula relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <input className="cm3Input" placeholder="Kubikaža"></input>
          <p className="cm3P">cm3</p> <p className="hide">Obavezno polje</p>
        </div>
      )}
      {kwDodajOglas && (
        <div className="kwContainer relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <input className="kwInput" placeholder="Kilovati"></input>
          <p className="kmP">kw</p> <p className="hide">Obavezno polje</p>
        </div>
      )}
      {ksDodajOglas && (
        <div className="hpContainer relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <input className="hpInput" placeholder="Konjska snaga"></input>
          <p className="kmP">ks</p> <p className="hide">Obavezno polje</p>
        </div>
      )}
      {tipHladjenja && (
        <div className="tipHladjenjaContainer relative">
          <select className="hladjenjeSelect">
            <option selected disabled>
              Tip hlađenja motora
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {kubikaza && (
        <div className="cm3Container relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <input className="cm3Input" placeholder="Kubikaža"></input>
          <p className="kmP">cm3</p> <p className="hide">Obavezno polje</p>
        </div>
      )}{" "}
      {stanje && (
        <div className="stanjeContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="stanjeSelect">
            <option selected disabled>
              Stanje
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {brSjedista && (
        <div className="tabliceContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="brSJedistaSelect">
            <option selected disabled>
              Broj sjedišta
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {tablice && (
        <div className="tabliceContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="tabliceSelect">
            <option selected disabled>
              Tablice
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {registracija && (
        <div className="regContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="regSelect">
            <option selected disabled>
              Registracija
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {pogon && (
        <div className="pogonContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="pogonSelect">
            <option selected disabled>
              Pogon
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {brCilindara && (
        <div className="brCilinadara relative">
          <select className="brCilindaraSelect">
            <option selected disabled>
              Broj cilindara
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {mjenjac && (
        <div className="mjenjacContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="mjenjacSelect">
            {" "}
            <option selected disabled>
              Mjenjač
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {brOsovina && (
        <div className="relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="brojOsovinaSelect">
            <option disabled selected>
              Broj osovina
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {brMotora && (
        <div className="relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="brojMotoraSelect">
            <option disabled selected>
              Broj motora
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {materijalSelect && (
        <div className="relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="materijalSelect">
            <option disabled selected>
              Materijal trupa
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {boja && (
        <div className="bojaContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="bojaSelect">
            <option selected disabled>
              Boja
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {ostecenje && (
        <div className="ostecenjeContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="ostecenjeSelect">
            <option selected disabled>
              Oštećenje
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {brVrata && (
        <div className="brVrataContainer relative">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="brVrataSelect">
            <option selected disabled>
              Broj vrata
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {brBrzinaMjenjaca && (
        <div className="relative brMjenjacaContainer">
          {showObaveznoPolje && <p className="obavezniPodatak">*</p>}
          <select className="brMjenjacaSelect">
            <option selected disabled>
              Broj brzina mjenjača
            </option>
          </select>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {brSati && (
        <div className="relative">
          <input
            placeholder="Broj radnih sati"
            className="brSatiDodajOglas"
          ></input>
        </div>
      )}
      {duzina && (
        <div className="duzinaContainer relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <input className="duzinaInputDodajOglas" placeholder="Dužina"></input>
          <p className="duzinaP">u metrima</p>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {sirina && (
        <div className="sirinaContainer relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <input className="sirinaInputDodajOglas" placeholder="Širina"></input>
          <p className="sirinaP">u metrima</p>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {cijenaDodajOglas && (
        <div className="cijenDodajOglasContainer relative">
          <p className="obavezniPodatak">*</p>
          <input className="cijenaDodajOglasInput" placeholder="Cijena"></input>
          <p className="cijenaDodajOglasP">€</p>{" "}
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {drzava && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}{" "}
          <select className="drzavaSelect">
            <option selected disabled>
              Država
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {grad && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}{" "}
          <select className="gradSelect">
            <option selected disabled>
              Grad
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {zamjena && (
        <div className="zamjenaDiv relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="zamjenaSelect">
            <option selected disabled>
              Zamjena
            </option>
          </select>

          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {tipVozila && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Tip vozila
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {sirinaGume && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Širina
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {visina && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Visina
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {sezona && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Sezona
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {komada && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Komada
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {materijalFelne && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Materijal
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {rasponRupa && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Raspon rupa
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {velicina && (
        <div className="relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}
          <select className="nosivostSelect">
            <option disabled selected>
              Veličina
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
      {cijena && (
        <div className="cijena">
          <div className="cijenaDiv">
            <div className="cijenaInput">
              <input
                className="cijenaOd"
                type="number"
                placeholder="Od"
              ></input>

              <p className="cijenaP">cijena</p>
            </div>
            <div className="cijenaInput">
              <input
                className="cijenaDo"
                type="number"
                placeholder="Do"
              ></input>

              <p className="cijenaP">cijena</p>
            </div>
          </div>
        </div>
      )}
      {duzinaOdDo && (
        <div className="duzinaDiv">
          <div className="duzinaInput">
            <input className="duzinaOd" type="number" placeholder="Od"></input>

            <p className="duzinaP">duzina(m)</p>
          </div>
          <div className="duzinaInput">
            <input className="duzinaDo" type="number" placeholder="Do"></input>

            <p className="duzinaP">duzina(m)</p>
          </div>
        </div>
      )}{" "}
      {sirinaOdDo && (
        <div className="sirinaDiv">
          <div className="sirinaInput">
            <input className="sirinaOd" type="number" placeholder="Od"></input>

            <p className="sirinaP">sirina(m)</p>
          </div>
          <div className="sirinaInput">
            <input className="sirinaDo" type="number" placeholder="Do"></input>

            <p className="sirinaP">sirina(m)</p>
          </div>
        </div>
      )}
      {godiste && (
        <div className="godiste">
          <div className="godisteDiv">
            <div className="godisteInput">
              <input
                className="godisteOd"
                type="number"
                placeholder="Od"
              ></input>

              <p className="godisteP">godište</p>
            </div>
            <div className="godisteInput">
              <input
                className="godisteDo"
                type="number"
                placeholder="Do"
              ></input>

              <p className="godisteP">godište</p>
            </div>
          </div>
        </div>
      )}
      {kilovati && (
        <div className="kw">
          <div className="kwDiv">
            <div className="kwInput">
              <input className="kwOd" type="number" placeholder="Od"></input>

              <p className="kwP">kw</p>
            </div>
            <div className="kwInput">
              <input className="kwDo" type="number" placeholder="Do"></input>

              <p className="kwP">kw</p>
            </div>
          </div>
        </div>
      )}
      {konjskeSnage && (
        <div className={hideElement ? "hideElement" : "ks"}>
          <div className="ksDiv">
            <div className="ksInput">
              <input className="ksOd" type="number" placeholder="Od"></input>

              <p className="ksP">ks</p>
            </div>
            <div className="ksInput">
              <input className="ksDo" type="number" placeholder="Do"></input>

              <p className="ksP">ks</p>
            </div>
          </div>
        </div>
      )}
      {brSatiOdDo && (
        <div className="brSati">
          <div className="brSatiDiv">
            <div className="brSatiInput">
              <input
                className="brSatiOd"
                type="number"
                placeholder="Od"
              ></input>

              <p className="brSatiP">radni sati</p>
            </div>
            <div className="brSatiInput">
              <input
                className="brSatiDo"
                type="number"
                placeholder="Do"
              ></input>

              <p className="brSatiP">radni sati</p>
            </div>
          </div>
        </div>
      )}
      {kubikazaOdDo && (
        <div className="cm3">
          <div className="cm3Div">
            <div className="cm3Input">
              <input className="cm3Od" type="number" placeholder="Od"></input>

              <p className="cm3P">cm3</p>
            </div>
            <div className="cm3Input">
              <input className="cm3Do" type="number" placeholder="Do"></input>

              <p className="cm3P">cm3</p>
            </div>
          </div>
        </div>
      )}
      {kilometraza && (
        <div className="km">
          <div className="kmDiv">
            <div className="kmInput">
              <input className="kmOd" type="number" placeholder="Od"></input>

              <p className="kmP">km</p>
            </div>
            <div className="kmInput">
              <input className="kmDo" type="number" placeholder="Do"></input>

              <p className="kmP">km</p>
            </div>
          </div>
        </div>
      )}
      {brojVrata && (
        <div className=" relative">
          {obavezniPodatak && <p className="obavezniPodatak">*</p>}{" "}
          <select className="drzavaSelect">
            <option selected disabled>
              Broj vrata
            </option>
          </select>
          <p className="hide">Obavezno polje</p>
        </div>
      )}
    </>
  );
}
