import React from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import "./menu.css";

import Tabbar from "../../components/Tabbar/Tabbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const naslov = "Sačuvane objave";

const Menu = ({ homeMenu, likedMenu, addPostMenu, porukeMenu, profilMenu }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [language, setLanguage] = useState("Crnogorski");
  const [showProfileMore, setShowProfileMore] = useState(false);
  const [showAdsMore, setShowAdsMore] = useState(false);
  const showAdsMoreHandler = () => {
    setShowAdsMore(true);
  };

  const hideAdsMoreHandler = () => {
    setShowAdsMore(false);
  };

  const showProfileMoreHandler = () => {
    setShowProfileMore(true);
  };

  const hideProfileMoreHandler = () => {
    setShowProfileMore(false);
  };

  const promeniJezik = (noviJezik) => {
    setLanguage(noviJezik);
    setOpenLanguage(false);
  };

  const otvoriJezik = () => {
    setOpenLanguage(!openLanguage);
  };

  const ZatvoriMenu = () => {
    setOpenMenu(false);
  };

  const OtvoriMenu = () => {
    setOpenMenu(true);
  };

  const nalog = true;
  return (
    <div className="menuContainer">
      <div className="bigMenu">
        <div className="zamraceniMenu"></div>
        <Link to="/">
          {" "}
          <img className="bijeliLogo" src="/icons/logoBijeli.png" />
        </Link>
        <div className="bigMenu2">
          {" "}
          <Link to="/">
            <img className="bigMenuIcons" src="/icons/bigMenuHome.png" />{" "}
            Početna
          </Link>
          <Link to="/sacuvani-oglasi">
            <img className="bigMenuIcons" src="/icons/bigMenuLike.png" />
            Sačuvane objave
          </Link>
          <Link to="/dodaj-oglas">
            <img className="bigMenuIcons" src="/icons/bigMenuAdd.png" />
            Dodaj oglas
          </Link>
          <Link to="/poruke">
            <img className="bigMenuIcons" src="/icons/bigMenuPoruke.png" />
            Poruke
          </Link>
          <Link
            className="profilLinkMenu"
            onMouseEnter={showProfileMoreHandler}
            onMouseLeave={hideProfileMoreHandler}
            to=""
          >
            <img
              className="bigMenuIcons profilMenuHover"
              src="/icons/bigMenuProfil.png"
            />
            Profil
          </Link>
          {showProfileMore && (
            <div
              onMouseEnter={showProfileMoreHandler}
              onMouseLeave={hideProfileMoreHandler}
              className="profilMore"
            >
              <Link
                onMouseEnter={() => {
                  showAdsMoreHandler();
                  showProfileMoreHandler();
                }}
                onMouseLeave={() => {
                  hideAdsMoreHandler();
                  hideProfileMoreHandler();
                }}
              >
                Moji oglasi <Link>&gt;</Link>
              </Link>
              <Link
                to="/profil/izmijeni-podatke"
                onMouseEnter={showProfileMoreHandler}
                onMouseLeave={hideProfileMoreHandler}
              >
                Uredi profil
              </Link>
              <Link
                to="/profil/dodaj-nacin-placanja"
                onMouseEnter={showProfileMoreHandler}
                onMouseLeave={hideProfileMoreHandler}
              >
                Dodaj način plaćanja
              </Link>
              <Link
                to="/profil/istorija-placanja"
                onMouseEnter={showProfileMoreHandler}
                onMouseLeave={hideProfileMoreHandler}
              >
                Istorija plaćanja
              </Link>
              <Link
                onMouseEnter={showProfileMoreHandler}
                onMouseLeave={hideProfileMoreHandler}
              >
                Uslovi korišćenja
              </Link>
              <Link
                onMouseEnter={showProfileMoreHandler}
                onMouseLeave={hideProfileMoreHandler}
              >
                Odjavi se
              </Link>
            </div>
          )}
          <div
            onMouseEnter={() => {
              showAdsMoreHandler();
              showProfileMoreHandler();
            }}
            onMouseLeave={() => {
              hideAdsMoreHandler();
              hideProfileMoreHandler();
            }}
            className={showAdsMore ? "oglasiMenuAds" : "hide"}
          >
            <Link to="/moji-oglasi/aktivni">Aktivni oglasi</Link>
            <Link to="/moji-oglasi/u-obradi">Oglasi u obradi</Link>
            <Link to="/statistika">Statistika oglasa</Link>
          </div>
        </div>
        <div className="bigMenu3">
          <div onClick={otvoriJezik} className="language relative">
            <img src="/icons/globe.png" />
            <p>{language}</p>
            <img className="strelicaJezik" src="/icons/dolje.png" />
          </div>
          <div className={openLanguage ? "selectLanguage" : "hide"}>
            <img
              className="jeziciIcons"
              src="/icons/me.png"
              onClick={() => promeniJezik("Crnogorski")}
            />
            <p onClick={() => promeniJezik("Crnogorski")}>Crnogorski</p>
            <img
              className="jeziciIcons"
              src="/icons/engleska.png"
              onClick={() => promeniJezik("English")}
            />
            <p onClick={() => promeniJezik("English")}>English</p>
            <img
              className="jeziciIcons"
              src="/icons/rusija.png"
              onClick={() => promeniJezik("Pусский")}
            />
            <p onClick={() => promeniJezik("Pусский")}>Pусский</p>
            <img
              className="jeziciIcons"
              src="/icons/turska.png"
              onClick={() => promeniJezik("Türkçe")}
            />
            <p onClick={() => promeniJezik("Türkçe")}>Türkçe</p>
          </div>
        </div>
      </div>
      <div
        onClick={ZatvoriMenu}
        className={openMenu ? "zamraceniDiv" : "zatvoriZamraceniDiv"}
      ></div>
      <div className={openMenu ? "openMenu" : "hideMenu"}>
        <img className="logoLeftMenu" src="/icons/logo.png" />
        <img
          onClick={ZatvoriMenu}
          className="closeMenu"
          src="/icons/closeMenu.png"
        />
        <Tabbar
          home={homeMenu}
          heart={likedMenu}
          dodajOglas={addPostMenu}
          poruke={porukeMenu}
          profil={profilMenu}
        />
        <div className="roundLanguageDiv">
          <img className="roundLanguage" src="/icons/me.png"></img>{" "}
          <img className="roundLanguage" src="/icons/engleska.png"></img>{" "}
          <img className="roundLanguage" src="/icons/rusija.png"></img>{" "}
          <img className="roundLanguage" src="/icons/turska.png"></img>
        </div>
      </div>
      <div className="menu">
        <img className="menuLogo" src="/icons/logo.png" />
        <img onClick={OtvoriMenu} className="menuBtn" src="/icons/menu.png" />
      </div>
    </div>
  );
};

export default Menu;
