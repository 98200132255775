import React from "react";
import { useState } from "react";

import HeaderPhone from "../../components/headerPhone/HeaderPhone";
import NoveObjave from "../../components/noveObjave/NoveObjave";

import PretragaFilter from "../../pages/pretragaFilter/PretragaFilter";

import Tabbar from "../../components/Tabbar/Tabbar";
import KategorijePretraga from "../../components/kategorijePretraga/KategorijePretraga";
import PretragaProfil from "../../components/pretragaProfil/PretragaProfil";

import { useContext } from "react";
import { GlobalContext } from "../../context/global";
export function Pocetna(showAll) {
  const { globalState: { filterSearch } = {}, setGlobalState } =
    useContext(GlobalContext);

  return (
    <div className="pocetnaContainer">
      <PretragaFilter />
      <div className="hr"></div>
      <PretragaProfil />
      <div className="hr"></div>
      <NoveObjave
        reklamePocetna="true"
        naslov="Nove objave"
        showAll={showAll}
      />
      <div className={filterSearch ? "hide" : "sdfsf"}>
        <Tabbar
          home="/icons/home.svg"
          heart="/icons/heart.png"
          dodajOglas="/icons/dodajOglas.svg"
          poruke="/icons/poruke.svg"
          profil="/icons/podesavanja.svg"
        />
      </div>
    </div>
  );
}
