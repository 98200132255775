import React from "react";
import "./filterPretraga.css";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import HeaderPhone from "../../components/headerPhone/HeaderPhone";

import ButoniPretraga from "../../components/butoniPretraga/ButoniPretraga";

import KategorijePretraga from "../../components/kategorijePretraga/KategorijePretraga";
import AutoFilter from "../../components/autoFilter/AutoFilter";

import { GlobalContext } from "../../context/global.js";

export default function PretragaFilter() {
  const da = true;
  const showObaveznoPolje = false;

  const [detaljnaOpen, setDetaljnaOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("gume");
  const handleClick = (option) => {
    setSelectedOption(option);
  };

  const {
    globalState: { selectedCategory, openDetaljnaPretraga, filterSearch } = {},
    setGlobalState,
  } = useContext(GlobalContext);
  console.log(selectedCategory);

  const openFilters = () => {
    setGlobalState((prevState) => ({
      ...prevState,
      filterSearch: true,
    }));
  };

  const handleClickOpenDetaljna = () => {
    setGlobalState((prevState) => ({
      ...prevState,
      openDetaljnaPretraga: !prevState.openDetaljnaPretraga,
    }));
  };

  const handleCategoryClick = (category) => {
    setGlobalState((prevState) => ({
      ...prevState,
      selectedCategory: category,
    }));
  };
  const closeSearchFilter = () => {
    setGlobalState((prevState) => ({
      ...prevState,
      filterSearch: false,
    }));
  };
  return (
    <div className="pretragaFilterr">
      <HeaderPhone filterKlikSearch={openFilters} />
      <div className={filterSearch ? "pretragaFilterMobile" : "hide"}>
        <div className="headerMobileSearch">
          <img
            onClick={closeSearchFilter}
            className="backMobileSaerch"
            src="/icons/back.svg"
          />
          <p>Pretraži</p> <img src="/icons/delete.png" />
        </div>
        <KategorijePretraga
          pocetna={true}
          pocetnaProfil={true}
          uslov={true}
          auto={
            selectedCategory === "auto"
              ? "/images/autoSelected.png"
              : "/images/autoSelect.png"
          }
          motor={
            selectedCategory === "motor"
              ? "/images/motorSelected.png"
              : "/images/motorSelect.png"
          }
          rent={
            selectedCategory === "rent"
              ? "/icons/selectedRent.svg"
              : "/icons/selectRent.svg"
          }
          bus={
            selectedCategory === "bus"
              ? "/images/busSelected.png"
              : "/images/busSelect.png"
          }
          kamion={
            selectedCategory === "kamion"
              ? "/images/kamionSelected.png"
              : "/images/kamionSelect.png"
          }
          prikolica={
            selectedCategory === "prikolica"
              ? "/images/prikolicaSelected.png"
              : "/images/prikolicaSelect.png"
          }
          jahta={
            selectedCategory === "jahta"
              ? "/images/jahtaSelected.png"
              : "/images/jahtaSelect.png"
          }
          masine={
            selectedCategory === "masine"
              ? "/images/masineSelected.png"
              : "/images/masineSelect.png"
          }
          traktor={
            selectedCategory === "traktor"
              ? "/images/traktorSelected.png"
              : "/images/traktorSelect.png"
          }
          tocak={
            selectedCategory === "tocak"
              ? "/images/tocakSelected.png"
              : "/images/tocakSelect.png"
          }
          styleKategorijeAuto={
            selectedCategory === "auto"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijeMotor={
            selectedCategory === "motor"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijeRent={
            selectedCategory === "rent"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijeBus={
            selectedCategory === "bus"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijeKamion={
            selectedCategory === "kamion"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijePrikolica={
            selectedCategory === "prikolica"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijeJahta={
            selectedCategory === "jahta"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijeMasine={
            selectedCategory === "asine"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijeTraktor={
            selectedCategory === "traktor"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          styleKategorijeTocak={
            selectedCategory === "tocak"
              ? "selectKategorija selectedDiv"
              : "selectKategorija"
          }
          LinkAuto="LinkBorderRight"
          LinkMotor="LinkBorderRight"
          LinkBus="LinkBorderRight"
          LinkKamion="LinkBorderRight"
          LinkPrikolica="LinkBorderRight"
          LinkJahta="LinkBorderRight"
          LinkMasine="LinkBorderRight"
          LinkTraktor="LinkBorderRight"
          LinkTocak="LinkBorderRight"
          LinkRent="LinkBorderRight"
        />
        <div
          className={
            selectedCategory === "auto" ||
            selectedCategory === "rent" ||
            selectedCategory === undefined
              ? "pretragaFilter"
              : detaljnaOpen
              ? "pretragaFilter bottomAuto"
              : "pretraga1 hide"
          }
        >
          <AutoFilter
            markaSelect={da}
            modelSelect={da}
            styleOdDo="motoriDetaljnaCss"
            godiste={da}
            cijena={da}
            zamjena={da}
            kubikazaOdDo={da}
            kilometraza={da}
            karoserija={da}
            kilovati={da}
            konjskeSnage={da}
            gorivo={da}
          />
          {openDetaljnaPretraga && (
            <AutoFilter
              stanje={da}
              tablice={da}
              registracija={da}
              pogon={da}
              brBrzinaMjenjaca={da}
              dodajOglasDrzavaGrad={da}
              mjenjac={da}
              boja={da}
              ostecenje={da}
              brojVrata={da}
            />
          )}
          <ButoniPretraga
            funkcijaButtona="Pretraži"
            detaljnaPretraga=""
            onClickDetaljna={handleClickOpenDetaljna}
            buttoniContainer="buttoniContainerMobile"
            pretraga="/pretraga-po-filterima"
            stilovi="stilovi"
          />
        </div>

        <div
          className={
            selectedCategory === "motor" ? "pretragaFilter" : "pretraga2 hide"
          }
        >
          <AutoFilter
            tip={da}
            markaSelect={da}
            modelInput={da}
            styleOdDo="motoriDetaljnaCss"
            godiste={da}
            cijena={da}
            zamjena={da}
            kubikazaOdDo={da}
            kilometraza={da}
            karoserija={da}
            gorivo={da}
          />
          {openDetaljnaPretraga && (
            <AutoFilter
              stanje={da}
              kilovati={da}
              konjskeSnage={da}
              tablice={da}
              registracija={da}
              pogon={da}
              brBrzinaMjenjaca={da}
              drzava={da}
              grad={da}
              mjenjac={da}
              boja={da}
              ostecenje={da}
            />
          )}
          <ButoniPretraga
            funkcijaButtona="Pretraži"
            detaljnaPretraga=""
            onClickDetaljna={handleClickOpenDetaljna}
            buttoniContainer="buttoniContainerMobile"
            pretraga="/pretraga-po-filterima"
            stilovi="stilovi"
          />
        </div>

        {selectedCategory === "bus" && (
          <div className="pretragaFilter">
            <AutoFilter
              tip={da}
              markaSelect={da}
              modelInput={da}
              styleOdDo="motoriDetaljnaCss"
              godiste={da}
              cijena={da}
              brSjedista={da}
              zamjena={da}
              kilometraza={da}
              karoserija={da}
              gorivo={da}
            />
            {openDetaljnaPretraga && (
              <AutoFilter
                stanje={da}
                kilovati={da}
                konjskeSnage={da}
                tablice={da}
                registracija={da}
                pogon={da}
                brBrzinaMjenjaca={da}
                drzava={da}
                grad={da}
                mjenjac={da}
                boja={da}
                ostecenje={da}
              />
            )}
            <ButoniPretraga
              funkcijaButtona="Pretraži"
              detaljnaPretraga=""
              onClickDetaljna={handleClickOpenDetaljna}
              buttoniContainer="buttoniContainerMobile"
              pretraga="/pretraga-po-filterima"
              stilovi="stilovi"
            />{" "}
          </div>
        )}
        <div
          className={
            selectedCategory === "kamion" ? "pretragaFilter" : "pretraga1 hide"
          }
        >
          <AutoFilter
            tip={da}
            markaSelect={da}
            modelInput={da}
            nosivost={da}
            karoserija={da}
            gorivo={da}
            zamjena={da}
            kilovati={da}
            godiste={da}
            cijena={da}
            kubikazaOdDo={da}
            kilometraza={da}
            styleOdDo="motoriDetaljnaCss"
          />
          {openDetaljnaPretraga && (
            <AutoFilter
              stanje={da}
              konjskeSnage={da}
              brOsovina={da}
              tablice={da}
              registracija={da}
              pogon={da}
              brBrzinaMjenjaca={da}
              drzava={da}
              grad={da}
              mjenjac={da}
              boja={da}
              ostecenje={da}
            />
          )}
          <ButoniPretraga
            funkcijaButtona="Pretraži"
            detaljnaPretraga=""
            onClickDetaljna={handleClickOpenDetaljna}
            buttoniContainer="buttoniContainerMobile"
            pretraga="/pretraga-po-filterima"
            stilovi="stilovi"
          />
        </div>
        <div
          className={
            selectedCategory === "prikolica"
              ? " pretragaFilter"
              : "pretraga1 hide"
          }
        >
          <AutoFilter
            tip={da}
            markaSelect={da}
            modelInput={da}
            styleOdDo="motoriDetaljnaCss"
            godiste={da}
            cijena={da}
            nosivost={da}
            zamjena={da}
            karoserija={da}
          />
          {openDetaljnaPretraga && (
            <AutoFilter
              stanje={da}
              brOsovina={da}
              drzava={da}
              grad={da}
              boja={da}
              ostecenje={da}
            />
          )}
          <ButoniPretraga
            funkcijaButtona="Pretraži"
            detaljnaPretraga=""
            onClickDetaljna={handleClickOpenDetaljna}
            buttoniContainer="buttoniContainerMobile"
            pretraga="/pretraga-po-filterima"
            stilovi="stilovi"
          />
        </div>

        <div
          className={
            selectedCategory === "masine" ? "pretragaFilter" : "pretraga1 hide"
          }
        >
          <AutoFilter
            styleOdDo="motoriDetaljnaCss"
            godiste={da}
            brSatiOdDo={da}
            zamjena={da}
            cijena={da}
            kubikazaOdDo={da}
            markaSelect={da}
            modelInput={da}
            tip={da}
            gorivo={da}
          />
          {openDetaljnaPretraga && (
            <AutoFilter
              stanje={da}
              tablice={da}
              registracija={da}
              pogon={da}
              drzava={da}
              grad={da}
              mjenjac={da}
              boja={da}
              ostecenje={da}
            />
          )}
          <ButoniPretraga
            funkcijaButtona="Pretraži"
            detaljnaPretraga=""
            onClickDetaljna={handleClickOpenDetaljna}
            buttoniContainer="buttoniContainerMobile"
            pretraga="/pretraga-po-filterima"
            stilovi="stilovi"
          />
        </div>
        <div
          className={
            selectedCategory === "traktor" ? "pretragaFilter" : "pretraga1 hide"
          }
        >
          <AutoFilter
            karoserija={da}
            styleOdDo="motoriDetaljnaCss"
            godiste={da}
            brSatiOdDo={da}
            zamjena={da}
            cijena={da}
            kubikazaOdDo={da}
            markaSelect={da}
            modelInput={da}
            tip={da}
            gorivo={da}
          />
          {openDetaljnaPretraga && (
            <AutoFilter
              stanje={da}
              tablice={da}
              registracija={da}
              pogon={da}
              brCilindara={da}
              drzava={da}
              grad={da}
              mjenjac={da}
              boja={da}
              ostecenje={da}
            />
          )}
          <ButoniPretraga
            funkcijaButtona="Pretraži"
            detaljnaPretraga=""
            onClickDetaljna={handleClickOpenDetaljna}
            buttoniContainer="buttoniContainerMobile"
            pretraga="/pretraga-po-filterima"
            stilovi="stilovi"
          />
        </div>
        <div
          className={
            selectedCategory === "jahta" ? "pretragaFilter" : "pretraga1 hide"
          }
        >
          <AutoFilter
            markaSelect={da}
            tip={da}
            modelInput={da}
            karoserija={da}
            gorivo={da}
            zamjena={da}
            sirinaOdDo={da}
            duzinaOdDo={da}
            cijena={da}
            styleOdDo="motoriDetaljnaCss"
            godiste={da}
            brSatiOdDo={da}
          />
          {openDetaljnaPretraga && (
            <AutoFilter
              stanje={da}
              kilovati={da}
              konjskeSnage={da}
              registracija={da}
              tablice={da}
              brMotora={da}
              materijalSelect={da}
              drzava={da}
              grad={da}
              boja={da}
              ostecenje={da}
            />
          )}
          <ButoniPretraga
            funkcijaButtona="Pretraži"
            detaljnaPretraga=""
            onClickDetaljna={handleClickOpenDetaljna}
            buttoniContainer="buttoniContainerMobile"
            pretraga="/pretraga-po-filterima"
            stilovi="stilovi"
          />
        </div>
        <div
          className={
            selectedCategory === "tocak" ? "pretragaFilter" : "pretraga1 hide"
          }
        >
          <div className="gumefelne">
            <p
              onClick={() => handleClick("gume")}
              className={
                selectedOption === "gume" ? "gumeP selectedGumeFelne" : "gumeP"
              }
            >
              Gume
            </p>
            <p
              onClick={() => handleClick("felneSaGumama")}
              className={
                selectedOption === "felneSaGumama"
                  ? "felneSaGumama selectedGumeFelne"
                  : "felneSaGumama"
              }
            >
              Felne sa gumama
            </p>
            <p
              onClick={() => handleClick("felne")}
              className={
                selectedOption === "felne"
                  ? "felneP selectedGumeFelne"
                  : "felneP"
              }
            >
              Felne
            </p>
          </div>
          <div
            className={selectedOption === "gume" ? "gumeMobileSearch" : "hide"}
          >
            <AutoFilter
              sirinaGume={da}
              visina={da}
              cijena={da}
              stanje={da}
              godiste={da}
              tipVozila={da}
              komada={da}
              sezona={da}
              markaSelect={da}
              modelInput={da}
              gume={da}
            />
          </div>
          <div
            className={selectedOption === "felne" ? "gumeMobileSearch" : "hide"}
          >
            <AutoFilter
              materijalFelne={da}
              velicina={da}
              cijena={da}
              stanje={da}
              rasponRupa={da}
              godiste={da}
              tipVozila={da}
              komada={da}
              markaSelect={da}
              modelInput={da}
            />
          </div>
          <div
            className={
              selectedOption === "felneSaGumama" ? "gumeMobileSearch" : "hide"
            }
          >
            <AutoFilter
              markaSelect={da}
              modelInput={da}
              velicina={da}
              rasponRupa={da}
              komada={da}
              sirinaGume={da}
              stanje={da}
              visina={da}
              cijena={da}
              godiste={da}
            />
          </div>
          <ButoniPretraga
            funkcijaButtona="Pretraži"
            onClickDetaljna={handleClickOpenDetaljna}
            buttoniContainer="pretraziContainerMobile"
            pretraga="/pretraga-po-filterima"
            stilovi="hide"
          />
        </div>
      </div>
    </div>
  );
}
