import "./logoutDodajOglas.css";

const da = true;
export default function LogoutDodajOglas() {
  return (
    <div className="LogoutDodajOglas">
      <div className="headerLogout">Novi korisnik</div>
      <div className="logOutAtributi">
        <div className="relative">
          {" "}
          <p style={{ top: "-1px" }} className="obavezniPodatak">
            *
          </p>
          <input placeholder="Ime" className="newImeInput" />
        </div>
        <div className="relative">
          <p style={{ top: "-1px" }} className="obavezniPodatak">
            *
          </p>
          <input placeholder="Prezime" className="newPrezimeInput" />
        </div>

        <div className=" relative">
          <p className="obavezniPodatak">*</p>
          <select className="pozivniBr">
            <option>+382</option>
          </select>
        </div>
        <div className="relative">
          <p className="obavezniPodatak">*</p>
          <input
            className="brojTelefona"
            type="number"
            placeholder="6x xxx xxx"
          ></input>
        </div>
      </div>
    </div>
  );
}
