import React, { useLayoutEffect, useRef, useState } from "react";

import "./slicniOglasi.css";
import NoveObjave from "../noveObjave/NoveObjave";

export default function SlicniOglasi({ showFavorited }) {

const naslov = "";
  return (
    <div className="slicniOglasi">
        <p className="slicniOglasiNaslov">Slični oglasi</p>
        <NoveObjave showAll="true" showFavorited={showFavorited} naslov={naslov} />
    </div>
  );
}
