import React, { useState, useEffect } from "react";
import "./noveObjave.css";
import { useContext } from "react";

import { Link } from "react-router-dom";
import { GlobalContext } from "../../context/global";

const da = true;
const oglasiDataInitial = [
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },
  {
    className: "oglas1 divOglas",
    gorivo: "Dizel",
    imgSrc: "/images/1.jpg",
    naslov: "Asssdkffnsfs mlkfds mk",
    cijena: "88f598€",
    vrijeme: "Prije 15 min",
    mjenjac: "Automatik",
    lokacija: "Bijeflo Polje",
    spremljen: false,
  },

  {
    className: "oglas2 divOglas",
    gorivo: "Benzin",
    imgSrc: "/images/2.jpg",
    naslov: "MMkfs mlkfds mk",
    cijena: "887598€",
    mjenjac: "Automatik",
    vrijeme: "Prije 2 min",
    lokacija: "Bijeloo Polje",
    spremljen: false,
  },
  {
    className: "oglas3 divOglas",
    imgSrc: "/images/3.jpg",
    gorivo: "Hibrid",
    naslov: "Asdkfkfs mlkfds mk",
    cijena: "8859g8€",
    mjenjac: "Manuel",
    vrijeme: "Prije 1 min",
    lokacija: "Bijgelo Polje",
    spremljen: false,
  },
  {
    className: "oglas4 divOglas",
    gorivo: "Plin",
    imgSrc: "/images/4.jpg",
    naslov: "Asdhnkfs mlkfds mk",
    cijena: "88h598€",
    vrijeme: "Prije 3 min",
    mjenjac: "Manuel",
    lokacija: "Bijhelo Polje",
    spremljen: false,
  },
  {
    className: "oglas5 divOglas",
    gorivo: "Ulje",
    imgSrc: "/images/4.jpg",
    naslov: "Asdhnkfs mlkfds mk",
    cijena: "88h598€",
    vrijeme: "Prije 3 min",
    mjenjac: "Manuel",
    lokacija: "Bijhelo Polje",
    spremljen: false,
  },
];

const NoveObjave = ({
  showFavorited,
  showAll,
  naslov,
  uporediAuto,
  prvoZatamnjivanje,
  dugmeUporedi,
  openUporedi,
  reklamePocetna,
}) => {
  const [isFlexView, setFlexView] = useState(false);
  const [isListaView, setListaView] = useState(false);
  const {
    globalState: { filterSearch, openDetaljnaPretraga } = {},
    setGlobalState,
  } = useContext(GlobalContext);
  const [isKockeView, setKockeView] = useState(false);
  const [oglasiData, setOglasiData] = useState(oglasiDataInitial);
  const [selectedAds, setSelectedAds] = useState([]);
  const [selectedOption, setSelectedOption] = useState("automobili");

  useEffect(() => {
    const savedSelectedAds = JSON.parse(localStorage.getItem("selectedAds"));
    if (savedSelectedAds && savedSelectedAds.length > 0) {
      setSelectedAds(savedSelectedAds);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedAds", JSON.stringify(selectedAds));
  }, [selectedAds]);
  useEffect(() => {
    setGlobalState((prevState) => ({
      ...prevState,
      openDetaljnaPretraga: true,
    }));
  }, [setGlobalState]);
  const handleAdClick = (index) => {
    if (!openUporedi) return;

    const adToUpdate = oglasiData[index];

    if (selectedAds.includes(index)) {
      const updatedSelectedAds = selectedAds.filter(
        (selectedAdIndex) => selectedAdIndex !== index
      );
      setSelectedAds(updatedSelectedAds);

      adToUpdate.className = adToUpdate.className.replace(
        " zatamniVozilo1",
        ""
      );
    } else {
      if (selectedAds.length < 2) {
        const updatedSelectedAds = [...selectedAds, index];
        setSelectedAds(updatedSelectedAds);

        adToUpdate.className += " zatamniVozilo1";
      } else {
        console.log("Možete odabrati samo dva oglasa.");
      }
    }

    const updatedOglasiData = [...oglasiData];
    updatedOglasiData[index] = adToUpdate;
    setOglasiData(updatedOglasiData);
  };

  const toggleView = () => {
    if (!isFlexView) {
      setFlexView(true);
    } else setFlexView(false);
  };

  const toggleKockeView = () => {
    setKockeView(!isKockeView);
    setListaView(false);
    setFlexView(false);
  };

  const toggleListaView = () => {
    if (!isListaView) {
      setListaView(true);
      setKockeView(false);
      setFlexView(false);
    }
  };

  const handleClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      {!filterSearch && (
        <div
          className={
            isListaView ? "noveObjave noveObjaveListaOglasi" : "noveObjave"
          }
        >
          <div className="rasporedOglasa">
            <p
              onClick={() => handleClick("sviOglasi")}
              className={
                selectedOption === "sviOglasi"
                  ? "sviOglasiP selectedSviOglasi"
                  : "sviOglasiP"
              }
            >
              Svi oglasi
            </p>
            <p
              onClick={() => handleClick("automobili")}
              className={
                selectedOption === "automobili"
                  ? "automobiliP selectedAutomobili"
                  : "automobiliP"
              }
            >
              Automobili
            </p>
            <p
              onClick={() => handleClick("zamjena")}
              className={
                selectedOption === "zamjena"
                  ? "zamjenaPRaspored selectedZamjena"
                  : "zamjenaPRaspored"
              }
            >
              Zamjena
            </p>
          </div>

          {dugmeUporedi && selectedAds.length > 0 && (
            <div className={dugmeUporedi ? "buttoniUporedi" : "hide"}>
              <Link to={selectedAds.length > 1 ? "/uporedjivanje-vozila" : ""}>
                {selectedAds.length > 1
                  ? "Uporedi vozila"
                  : "Izaberite drugo vozilo"}
              </Link>
            </div>
          )}
          <div className="noveObjaveHeader">
            <h1 className="noveObjaveH1">{naslov}</h1>
            <img
              className="kocke"
              src="/icons/kocke.png"
              onClick={toggleView}
            />

            <div className={`viewBlockCon${isFlexView ? " flex" : ""}`}>
              <div
                className="viewBlockDivKocke"
                onClick={() => toggleKockeView()}
              >
                <img className="viewBlockIcons" src="/icons/kocke.png" />
                <p className="viewBlockP">Kocke</p>
              </div>
              <div
                className="viewBlockDivLista"
                onClick={() => toggleListaView()}
              >
                <img className="viewBlockIcons" src="/icons/lista.png" />
                <p className="viewBlockP">Lista</p>
              </div>
            </div>
          </div>
          <div className="pcFilterSideDiv">
            <div className="pcFilterSide">
              <p className="pcFilterDetaljnaPretraga">Detaljna pretraga</p>
            </div>
            <div className="noveObjaveContainerAds">
              <div
                className={`noveObjaveOglasi ${
                  isListaView ? "noveObjaveLista" : ""
                } ${isKockeView ? "noveObjaveKocke" : ""}`}
              >
                {oglasiData
                  .filter((oglas) => (showAll ? true : oglas.spremljen))
                  .map((oglas, index) => (
                    <div
                      onClick={() => handleAdClick(index)}
                      className={`${oglas.className} ${
                        isListaView ? "divOglasLista" : ""
                      } ${isKockeView ? "divOglasKocke" : ""} ${
                        uporediAuto ? "animated-border" : ""
                      }
              `}
                      key={index}
                    >
                      <div className={uporediAuto ? "zIndex-1" : ""}>
                        <div className="divSlikeNoviOglas">
                          <div className="okvirOglasSlike">
                            {" "}
                            <div>
                              <p className="sponzorisano">Top</p>

                              <div className="donjiTrougao"></div>
                              <div className="gornjiTrougao"></div>
                            </div>
                            <Link to={uporediAuto ? "" : "/oglas"}>
                              <div class="hover01 column">
                                <div>
                                  <figure>
                                    {" "}
                                    <img
                                      className={`slikeNovihOglasa ${
                                        isListaView
                                          ? "slikeNovihOglasaLista"
                                          : ""
                                      } ${
                                        isKockeView
                                          ? "slikeNovihOglasaKocke"
                                          : ""
                                      }`}
                                      src={oglas.imgSrc}
                                    />
                                  </figure>
                                  <div className="animationHover">
                                    <section className="sectionHoverImg">
                                      <img
                                        className="brSlikaHover"
                                        src="/icons/slike.png"
                                      />{" "}
                                      <p>15</p>
                                    </section>
                                    <section className="averStarHover">
                                      <p>4.4</p>
                                      <img
                                        className="averStar"
                                        src="/icons/star.png"
                                      />
                                    </section>{" "}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className={isListaView ? "samoListe" : ""}>
                            <div className="divNaslovCijena">
                              {" "}
                              <Link
                                to="/oglas"
                                className={`naslovOglasa ${
                                  isListaView ? "naslovOglasaLista" : ""
                                } ${isKockeView ? "naslovOglasaKocke" : ""}`}
                              >
                                {oglas.naslov}
                              </Link>
                              <p
                                className={`cijenaOglasa ${
                                  isListaView ? "cijenaOglasaLista" : ""
                                } ${isKockeView ? "cijenaOglasaKocke" : ""}`}
                              >
                                {oglas.cijena}
                              </p>
                            </div>
                            <div className="vrijemeLokacija">
                              <div
                                className={isListaView ? "listaPodaci" : "hide"}
                              >
                                <img
                                  className="listaPodaciIcons"
                                  src="/icons/car.png"
                                />
                                <p className="listaPodaciP">Limuzina</p>
                              </div>
                              <div
                                className={isListaView ? "listaPodaci" : "hide"}
                              >
                                <img
                                  className="listaPodaciIcons"
                                  src="/icons/motor.png"
                                />
                                <p className="listaPodaciP">103kw / 140ks</p>
                              </div>
                              <div
                                className={
                                  isListaView ? "listaPodaci" : "kockaPodaci"
                                }
                              >
                                <img
                                  className={
                                    isListaView
                                      ? "listaPodaciIcons"
                                      : "kockaPodaciIcons"
                                  }
                                  src="/icons/tocak.png"
                                />
                                <p
                                  className={
                                    isListaView
                                      ? "listaPodaciP"
                                      : "kockaPodaciP"
                                  }
                                >
                                  3423434 km
                                </p>
                              </div>
                              <div
                                className={
                                  isListaView ? "listaPodaci" : "kockaPodaci"
                                }
                              >
                                <img
                                  className={
                                    isListaView
                                      ? "listaPodaciIcons"
                                      : "kockaPodaciIcons"
                                  }
                                  src="/icons/kalendar.png"
                                />
                                <p
                                  className={
                                    isListaView
                                      ? "listaPodaciP"
                                      : "kockaPodaciP"
                                  }
                                >
                                  2010
                                </p>
                              </div>

                              <div
                                className={`mjenjacOglas ${
                                  isListaView ? "mjenjacOglasLista" : ""
                                } ${isKockeView ? "mjenjacOglasKocke" : ""}`}
                              >
                                <img
                                  className="mjenjacIcons"
                                  src="/icons/manual.png"
                                />
                                <p className="mjenjacOglasP">{oglas.mjenjac}</p>
                              </div>
                              <div
                                className={`gorivoOglas ${
                                  isListaView ? "gorivoOglasLista" : ""
                                } ${isKockeView ? "gorivoOglasKocke" : ""}`}
                              >
                                <img
                                  className="gorivoIcons"
                                  src="/icons/pumpa.png"
                                />
                                <p className="gorivoOglasP">{oglas.gorivo}</p>
                              </div>
                              <div
                                className={isListaView ? "listaPodaci" : "hide"}
                              >
                                <img
                                  className="listaPodaciIcons"
                                  src="/icons/pogon.png"
                                />
                                <p className="listaPodaciP">Prednji pogon</p>
                              </div>
                              <div className="divLokacija">
                                <img
                                  className={
                                    isListaView
                                      ? "listaPodaciIcons"
                                      : "kockaPodaciIcons"
                                  }
                                  src="/icons/lokacija.png"
                                />
                                <p className="lokacijaOglasa">
                                  {oglas.lokacija}
                                </p>
                              </div>
                              <div className="divVrijeme">
                                <img
                                  className="vrijemeIcons"
                                  src="/icons/vrijeme.png"
                                />
                                <p className="vrijemeOglasa">{oglas.vrijeme}</p>
                              </div>
                              <div
                                className={
                                  isListaView ? "listaPodaciProdavac" : "hide"
                                }
                              >
                                <p className="prodavacP">Prodavac:</p>
                                <p>Dino, +382675283456</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="reklameNoveObjave">
              {" "}
              <div className="divAutoOglasnikNaslov">
                <p className="autoOglasnik">AutoKlik oglasnik</p>
              </div>
              <a
                className="aSelect"
                href="https://www.instagram.com/car_detailing_centar/"
              >
                <img className="reklameImg" src="/images/dino.png" />
              </a>
              <div className="placeForAds">
                Mjesto za vašu reklamu! Kontaktirajte nas: info@autoklik.me
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NoveObjave;
