import React, { useState } from "react";
import Tabbar from "../../components/Tabbar/Tabbar";
import { Link } from "react-router-dom";
import "./dodajOglas.css";
import Kategorije from "../../components/kategorije/Kategorije";
import Upload from "../../components/upload/Upload";
import Opis from "../../components/opis/Opis";

import AutoFilter from "../../components/autoFilter/AutoFilter";
import AddPostSearch from "../../components/addPostSearch/AddPostSearch";
import ButoniPretraga from "../../components/butoniPretraga/ButoniPretraga";
import { useContext } from "react";
import { GlobalContext } from "../../context/global";

import Menu from "../../components/menu/Menu";

export default function DodajOglas() {
  const showObaveznoPolje = true;
  const da = true;
  const { globalState, kliknutaKategorija } = useContext(GlobalContext);
  const { kategorija } = globalState;
  const [openAddPostSearch, setOpenAddPostSearch] = useState(false);
  const handleButtonClick = (event) => {
    event.preventDefault();
    setOpenAddPostSearch(true);
  };
  const closeHandleButtonClick = (event) => {
    event.preventDefault();
    setOpenAddPostSearch(false);
  };
  return (
    <div className="dodajOglas">
      <div className={openAddPostSearch ? "" : "hide"}>
        <AddPostSearch closeButtonDodajOglas={closeHandleButtonClick} />
      </div>
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglass.png"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/podesavanja.svg"
      />
      <div className={openAddPostSearch ? "hide" : "dodajOglasDiv"}>
        <div className="dodajOglasHeaderContainer">
          <Link className="back" to="/">
            <img src="/icons/back.png" className="backBtn" />
          </Link>
          <img className="logoDodajOglas" src="/icons/logo.png" />
        </div>
        <div className="objasnjenjeDiv">
          <p className="red">*</p>
          <p className="objasnjenjeP">- obavezno polje</p>
        </div>
        <div className="h1DivDodajOglas">
          <h1 className="h1DodajOglas">Dodaj oglas</h1>
          <p className="koraci">1/3</p>
        </div>

        <div className="atributi">
          <Upload />
          <div className="horizontal-line"></div>
          <Kategorije />
          <Opis />
          <div className="podaciPcDodajOglas">
            <AutoFilter
              cijenaDodajOglas={da}
              obavezniPodatak={da}
              dodajOglasDrzavaGrad={da}
            />
          </div>
          <div className="horizontal-line"></div>

          <ButoniPretraga
            onClick={handleButtonClick}
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            pretraga=""
            stilovi="hide"
          />
        </div>
      </div>

      <Tabbar
        home="/icons/homee.svg"
        heart="/icons/heart.png"
        dodajOglas="/icons/dodajOglass.png"
        poruke="/icons/poruke.svg"
        profil="/icons/podesavanja.svg"
      />
    </div>
  );
}
