import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import Swiper from "../../components/swiper/index.js";

import "./oglas.css";
import RatingStars from "../../components/ratingStars/RatingStars";
import Menu from "../../components/menu/Menu";
import SlicniOglasi from "../../components/slicniOglasi/SlicniOglasi.js";
import Recenzije from "../../components/recenzije/Recenzije.js";
const unutrasnjaOprema = [
  "Grijanje volana",
  "Pristup invalidnim osobama",
  "Bluetooth",
  "On-board kompjuter",
  "CD player",
  "Električni prozori",
  "Električno bočno ogledalo",
  "Električno podešavanje sedišta",
  "Električno podešavanje sedišta, pozadi",
  "Head-Up displej",
  "Indukcijsko punjenje za pametne telefone",
  "Auto unutrašnji retrovizor zatamnjivanje",
  "Kožni volan",
  "Masaža sjedišta",
  "Upozorenje na umor",
  "Multifunkcionalni volan",
  "Navigacijski sistem",
  "Sistem za hitne pozive",
  "Radio DAB",
];
const oprema = [
  "Krovni nosači",
  "Električna vrata prtljažnika",
  "Električni imobilajzer",
  "ESP",
  "Pomoćnik za duga svjetla",
  "Sistem ograničenja brzine",
  "Svetlo za krivine",
  "Lasersko svjetlo",
  "Kontrola pritiska u gumama",
  "Šiber",
  "Centralno zaključavanje bez ključa",
  "Servo upravljač",
  "Sportska suspenzija",
  "Sportski paket",
  "Pomoćnik za održavanje trake",
  "Automatski start/stop",
];

const infoProdavac = {
  ime: "Dino",
  lokacija: "Crna Gora, Bijelo Polje",
  brTelefona: "Broj telefona",
  brTelefonaValue: "+382 12 345 678",
  prosjecnaOcjena: "4.5",
};

const images = [
  "/images/3.jpg",
  "/images/1.jpg",
  "/images/4.jpg",
  "/images/2.jpg",
  "/images/1.jpg",
  "/images/dino.jpg",
  "/images/2.jpg",
  "/images/2.jpg",
];
const carAdInfo = {
  marka: "Marka",
  markaValue: "Volkswagen",

  model: "Model",
  modelValue: "Passat CC",

  kubikaza: "Kubikaza",
  kubikazaValue: "2000 cm3",

  karoserija: "Karoserija",
  karoserijaValue: "Limuzina",

  oznaka: "Oznaka",
  oznakaValue: "2.0 tdi",

  pogon: "Pogon",
  pogonValue: "Prednji",

  tablice: "Tablice",
  tabliceValue: "Domaće",

  registrovanDo: "Registrovan do",
  registrovanDoValue: "12/2024",

  zamjena: "Zamjena",
  zamjenaValue: "Svejedno",

  ostecenje: "Oštećenje",
  ostecenjeValue: "Nema",

  brojBrzina: "Broj brzina",
  brojBrzinaValue: "6",

  brojVrata: "Broj vrata",
  brojVrataValue: "4/5",

  boja: "Boja",
  bojaValue: "Crna",

  opis: "Opis",
  opisValue:
    " ion f f f d fsdf sdf dsjdsfn iuhfdsi bsdf sdf dsjdsfn iuhfdsi bsdf sdf dsjdsfn iuhfdsi bsdf sdf dsjdsfn iuhfdsi bsdf sdf dsjdsfn iuhfdsi bsdf sdf dsjdsfn iuhfdsi bsdf sdf dsjdsfn iuhfdsi bsdf sdf dsjdsfn iuhfdsi bsdjk bfkdsj bdsjkf bdsjk bfsdjk bkjdsbkjsfd jdf kjsdb fjkdsj f sdf",
};
const carAd = {
  markaModelCijenaDiv: {
    naslovOglasaAds: "VW Passat CC",
    cijenaOglasaAds: "10000€",
  },
  karakteristikeOglasa: {
    godinaAds: {
      imgSrc: "/icons/kalendar.png",
      godinaAdsP: "2010",
    },
    gorivoAds: {
      imgSrc: "/icons/pumpa.png",
      gorivoAdsP: "Dizel",
    },
    kmAds: {
      imgSrc: "/icons/tocak.png",
      kmAdsP: "260000 km",
    },
    snagaAds: {
      imgSrc: "/icons/motor.png",
      snagaAdsP: "103kw/140ks",
    },
    mjenjacAds: {
      imgSrc: "/icons/manual.png",
      mjenjacAdsP: "Automatik",
    },
    lokacijaAds: {
      imgSrc: "/icons/lokacija.png",
      lokacijaAdsP: "Bijelo Polje",
    },
  },
};
const Oglas = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDropInfoVisible, setIsDropInfoVisible] = useState(true);
  const [isOpenImage, setIsOpenImage] = useState(false);
  const [isCloseImage, setIsCloseImage] = useState(true);
  const [clickReport, setClickReport] = useState(true);
  const [openPonuda, setOpenPonuda] = useState(false);
  const [closePonudaBtn, setClosePonudaBtn] = useState(false);
  const [closeSave, setCloseSave] = useState(false);
  const [errorPonuda, setErrorPonuda] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [empty, setEmpty] = useState("true");
  const [saveAds, setSaveAds] = useState(false);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.trim() === "") {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  };
  const clickPonuda = () => {
    setOpenPonuda(!openPonuda);
  };
  const closePonuda = () => {
    if (empty) {
      setErrorPonuda(!errorPonuda);
    } else {
      setClosePonudaBtn(!closePonudaBtn);
      setErrorPonuda(false);
      setTimeout(() => {
        setOpenPonuda(false);
        setClosePonudaBtn(false);
      }, 2000);
    }
  };

  const saveAsAds = () => {
    setSaveAds(!saveAds);
    if (clickCount % 2 === 0) {
      setCloseSave(true);
      setTimeout(() => {
        setCloseSave(false);
      }, 3000);
    } else {
      setCloseSave(false);
    }
    setClickCount(clickCount + 1);
  };

  const closedReport = () => {
    setClickReport(true);
  };
  const clickedReport = () => {
    setClickReport(false);
  };

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };

  const handleImageClick = () => {
    setIsOpenImage(true);
    if (window.innerWidth > 777) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const handleImageClose = () => {
    setIsOpenImage(false);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = currentScrollPos < prevScrollPos;

      setIsDropInfoVisible(isScrollingUp);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglass.png"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/podesavanja.svg"
      />

      <div className={`${clickReport ? " hide" : "showTranzicija"} `}>
        <div
          onClick={closedReport}
          className={`${clickReport ? "hide" : "divReporta"} `}
        ></div>
        <div
          className={`${clickReport ? "prijaviDiv hide" : "prijaviDiv"} ${
            clickReport ? "hide" : "prijaviDiv"
          } `}
        >
          <p className="prijaviteOglasNaslov">Prijavite oglas</p>
          <textarea
            placeholder="Napišite razlog"
            className="razlogPrijave"
          ></textarea>
          <button className="btnPrijavi">Prijavi</button>
        </div>
      </div>

      <div className={` ${clickReport ? "" : "zatamnjeniDiv"}`}>
        <div className="oglas">
          <div className={`openImage ${isOpenImage ? "displayBlock" : ""}`}>
            <img
              src="/icons/close.png"
              className="closeBtn"
              onClick={handleImageClose}
            />
            <div className="divOglasSlikaa">
              <Swiper
                pagination={{ clickable: true }}
                onSlideChange={handleSlideChange}
                images={images}
                handleImageClick={handleImageClick}
                currentIndex={currentIndex}
              />
            </div>
          </div>
          <div className={`headerAds ${isOpenImage ? "displayHide" : ""}`}>
            <Link to="/" className="nazadOglas">
              <img src="/icons/back.png" className="backAdsBtn" alt="Back" />
            </Link>
            <div
              className={`dropInfo ${
                isDropInfoVisible ? "showDropInfo" : "hideDropInfo"
              }`}
            >
              <p className="dropInfoNaslov">Volkswagen, Passac CC</p>
            </div>
            <div className="headerIconsPc">
              <div onClick={saveAsAds}>
                <p>Sačuvaj oglas</p>
                <img
                  className="shareIcons"
                  src={saveAds ? "/icons/heartt.png" : "/icons/savedHeart.png"}
                />
                <p className={closeSave ? "oglasSacuvanp" : "hide"}>
                  Oglas sačuvan!
                </p>
              </div>
              <div>
                <p>Podijeli</p>

                <img className="shareIcons" src="/icons/share.png" />
              </div>
              <div onClick={clickedReport}>
                <p>Prijavi</p>
                <img
                  onClick={clickedReport}
                  className="reportIcons"
                  src="/icons/report.png"
                />
              </div>
            </div>
          </div>
          <div className="bigContainerOglas">
            <div className="pcHeaderAds">
              <div>
                <div>
                  <img className="nazadPcVer" src="/icons/strelicaLijevo.png" />{" "}
                  Nazad
                </div>
              </div>
              <div className="headerIconsPc">
                <div onClick={saveAsAds}>
                  Sačuvaj oglas
                  <img
                    className="shareIcons"
                    src={saveAds ? "/icons/heartt.png" : "/icons/heart.png"}
                  />
                  <p className={closeSave ? "oglasSacuvanp" : "hide"}>
                    Oglas sačuvan!
                  </p>
                </div>
                <div>
                  Podijeli
                  <img className="shareIcons" src="/icons/share.png" />
                </div>
                <div onClick={clickedReport}>
                  Prijavi
                  <img
                    onClick={clickedReport}
                    className="reportIcons"
                    src="/icons/report.png"
                  />
                </div>
              </div>
            </div>
            <div className="container1Ads">
              <div className="divOglasSlika preko1200Hide">
                <Swiper
                  pagination={{ clickable: true }}
                  onSlideChange={handleSlideChange}
                  images={images}
                  handleImageClick={handleImageClick}
                  currentIndex={currentIndex}
                />
              </div>

              <div class="opisOglasa">
                <div class="markaModelCijenaDiv">
                  <p class="naslovOglasaAds">
                    {carAd.markaModelCijenaDiv.naslovOglasaAds}
                  </p>
                  <p class="cijenaOglasaAds">
                    {carAd.markaModelCijenaDiv.cijenaOglasaAds}
                  </p>
                </div>
                <div class="karakteristikeOglasa">
                  <div class="godinaAds">
                    <img
                      class="gorivoIcons"
                      src={carAd.karakteristikeOglasa.godinaAds.imgSrc}
                    />
                    <p class="godinaAdsP">
                      {carAd.karakteristikeOglasa.godinaAds.godinaAdsP}
                    </p>
                  </div>
                  <div class="gorivoAds">
                    <img
                      class="gorivoIcons"
                      src={carAd.karakteristikeOglasa.gorivoAds.imgSrc}
                    />
                    <p class="gorivoAdsP">
                      {carAd.karakteristikeOglasa.gorivoAds.gorivoAdsP}
                    </p>
                  </div>
                  <div class="kmAds">
                    <img
                      class="gorivoIcons"
                      src={carAd.karakteristikeOglasa.kmAds.imgSrc}
                    />
                    <p class="kmAdsP">
                      {carAd.karakteristikeOglasa.kmAds.kmAdsP}
                    </p>
                  </div>
                  <div class="snagaAds">
                    <img
                      class="snagaIcons"
                      src={carAd.karakteristikeOglasa.snagaAds.imgSrc}
                    />
                    <p class="snagaAdsP">
                      {carAd.karakteristikeOglasa.snagaAds.snagaAdsP}
                    </p>
                  </div>
                  <div class="mjenjacAds">
                    <img
                      class="mjenjacIcons"
                      src={carAd.karakteristikeOglasa.mjenjacAds.imgSrc}
                    />
                    <p class="mjenjacAdsP">
                      {carAd.karakteristikeOglasa.mjenjacAds.mjenjacAdsP}
                    </p>
                  </div>
                  <div class="lokacijaAds">
                    <img
                      class="lokacijaIcons"
                      src={carAd.karakteristikeOglasa.lokacijaAds.imgSrc}
                    />
                    <p class="lokacijaAdsP">
                      {carAd.karakteristikeOglasa.lokacijaAds.lokacijaAdsP}
                    </p>
                  </div>
                </div>
              </div>
              <div className="sticky hide">
                <div className={openPonuda ? "ponudaDiv" : "hide"}>
                  <div className="ponudaDivInput">
                    <input
                      onChange={handleInputChange}
                      className={openPonuda ? "ponudaInput" : "hide"}
                      type="number"
                      placeholder="Unesite iznos"
                    />
                    <p className={errorPonuda ? "errorPonuda show" : "hide"}>
                      Unesite ponudu!
                    </p>
                    <p className="ponudaEuro">€</p>
                  </div>

                  <p className={closePonudaBtn ? "ponudaPoslata" : "hide"}>
                    Ponuda poslata!
                  </p>
                  <button onClick={closePonuda} className="posaljiPonudu">
                    Pošalji ponudu €
                  </button>
                </div>
                <button
                  onClick={clickPonuda}
                  className={openPonuda ? "hide" : "posaljiPonudu"}
                >
                  Pošalji ponudu €
                </button>
                <div className={`container2Ads pcVerision`}>
                  <p className="osnovneInformacijeAds">Osnovne informacije</p>
                  <div className="hr"></div>
                  <div className="markaModelAds">
                    <p className="markaModelNaslov">Marka</p>
                    <p className="markaModelP">Volkswagen</p>
                  </div>
                  <div className="markaModelAds">
                    <p className="markaModelNaslov">Model</p>
                    <p className="markaModelP">Passat cc</p>
                  </div>
                  <div className="cm3Ads">
                    <p className="cm3Naslov">Kubikaža</p>
                    <p className="cm3PAds">2000 cm3</p>
                  </div>
                  <div className="oznakaAds">
                    <p className="oznakaNaslov">Oznaka</p>
                    <p className="oznakaP">2.0 TDI</p>
                  </div>{" "}
                  <div className="karoserijaAds">
                    <p className="karoserijaNaslov">Karoserija</p>
                    <p className="karoserijaP">Limuzina</p>
                  </div>
                  <div className="pogonAds">
                    <p className="pogonNaslov">Pogon</p>
                    <p className="pogonP">Prednji</p>
                  </div>
                  <div className="tabliceAds">
                    <p className="tabliceNaslov">Tablice</p>
                    <p className="tabliceP">Domaće</p>
                  </div>
                  <div className="regAds">
                    <p className="regNaslov">Registrovan do</p>
                    <p className="regP">12/2024</p>
                  </div>
                  <div className="zamjenaAds">
                    <p className="zamjenaNaslov">Zamjena</p>
                    <p className="zamjenaP">Ne</p>
                  </div>
                  <div className="ostecenjeAds">
                    <p className="ostecenjeNaslov">Oštećenje</p>
                    <p className="ostecenjeP">Nema</p>
                  </div>
                  <div className="brBrzinaAds">
                    <p className="brBrzinaNaslov">Broj brzina</p>
                    <p className="brBrzinaP">6</p>
                  </div>
                  <div className="brVrataAds">
                    <p className="brVrataNaslov">Broj vrata</p>
                    <p className="brVrataPOglas">4/5</p>
                  </div>
                  <div className="bojaAds">
                    <p className="bojaNaslov">Boja</p>
                    <p className="bojaP">Crna</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2Ads nonPcVerision">
              <p className="osnovneInformacijeAds">Osnovne informacije</p>
              <div className="hr"></div>
              <div className="markaModelAds">
                <p className="markaModelNaslov">
                  {carAdInfo.marka}, {carAdInfo.model}
                </p>
                <p className="markaModelP">
                  {carAdInfo.markaValue}, {carAdInfo.modelValue}
                </p>
              </div>
              <div className="cm3Ads">
                <p className="cm3Naslov">{carAdInfo.kubikaza}</p>
                <p className="cm3PAds">{carAdInfo.kubikazaValue}</p>
              </div>
              <div className="oznakaAds">
                <p className="oznakaNaslov">{carAdInfo.oznaka}</p>
                <p className="oznakaP">{carAdInfo.oznakaValue}</p>
              </div>{" "}
              <div className="karoserijaAds">
                <p className="karoserijaNaslov">{carAdInfo.karoserija}</p>
                <p className="karoserijaP">{carAdInfo.karoserijaValue}</p>
              </div>
              <div className="pogonAds">
                <p className="pogonNaslov">{carAdInfo.pogon}</p>
                <p className="pogonP">{carAdInfo.pogonValue}</p>
              </div>
              <div className="tabliceAds">
                <p className="tabliceNaslov">{carAdInfo.tablice}</p>
                <p className="tabliceP">{carAdInfo.tabliceValue}</p>
              </div>
              <div className="regAds">
                <p className="regNaslov">{carAdInfo.registrovanDo}</p>
                <p className="regP">{carAdInfo.registrovanDoValue}</p>
              </div>
              <div className="zamjenaAds">
                <p className="zamjenaNaslov">{carAdInfo.zamjena}</p>
                <p className="zamjenaP">{carAdInfo.zamjenaValue}</p>
              </div>
              <div className="ostecenjeAds">
                <p className="ostecenjeNaslov">{carAdInfo.ostecenje}</p>
                <p className="ostecenjeP">{carAdInfo.ostecenjeValue}</p>
              </div>
              <div className="brBrzinaAds">
                <p className="brBrzinaNaslov">{carAdInfo.brojBrzina}</p>
                <p className="brBrzinaP">{carAdInfo.brojBrzinaValue}</p>
              </div>
              <div className="brVrataAds">
                <p className="brVrataNaslov">{carAdInfo.brojVrata}</p>
                <p className="brVrataPOglas">{carAdInfo.brojVrataValue}</p>
              </div>
              <div className="bojaAds">
                <p className="bojaNaslov">{carAdInfo.boja}</p>
                <p className="bojaP">{carAdInfo.bojaValue}</p>
              </div>
            </div>
          </div>
          <div className="stickyContainer">
            <div>
              <div className="divOglasSlika">
                <Swiper
                  pagination={{ clickable: true }}
                  onSlideChange={handleSlideChange}
                  images={images}
                  handleImageClick={handleImageClick}
                  currentIndex={currentIndex}
                />
              </div>
              <div className="containerDodatnaOprema">
                <p className="dodatneInformacijeAdsNaslov">
                  Dodatne informacije
                </p>
                <div className="hr"></div>
                <div className="dodatnaOpremaAds">
                  <p className="dodatnaOpremaAdsNaslov">Dodatna oprema</p>
                  <div className="dodatnaOpremaPAds">
                    {" "}
                    {oprema.map((oprema, index) => (
                      <p key={index} className="dodatnaOpremaP">
                        {oprema}
                        <img className="correct" src="/icons/correct.png" />
                      </p>
                    ))}
                  </div>
                </div>
                <div className="dodatnaOpremaAds">
                  <p className="unutrasnjaOpremaAdsNaslov">Unutrašnja oprema</p>
                  <div className="dodatnaOpremaPAds">
                    {unutrasnjaOprema.map((unutrasnjaOprema, index) => (
                      <p key={index} className="dodatnaOpremaP">
                        {unutrasnjaOprema}
                        <img className="correct" src="/icons/correct.png" />
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="opisAdsContainer">
                <p className="opisNaslovAds">{carAdInfo.opis}</p>
                <div className="hrOpis"></div>
                <p className="opisTekst">{carAdInfo.opisValue}</p>
              </div>
              <div className="containerUserInfoAds">
                <div className="slikaImeAds">
                  <div className="prosjecnaOcjena">
                    {" "}
                    <p className="prosjecnaOcjenaP">
                      {infoProdavac.prosjecnaOcjena}
                    </p>
                    <img className="averStar" src="/icons/star.png" />
                  </div>
                  <img className="profilnaSlika" src="/images/pic.jpg" />
                  <div>
                    <div>
                      <p className="imeUser">{infoProdavac.ime}</p>
                      <Link to="/oglasi-prodavca" className="ostaliOglasi">
                        Pogledajte sve oglase prodavca
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="hrLokacija"></div>
                <div className="lokacijaAdsDiv">
                  <img className="lokacijaIcons" src="/icons/location.png" />
                  <p className="lokacijaPAds">{infoProdavac.lokacija}</p>
                </div>
                <div className="hrLokacija"></div>
                <p className="brTelefonaNaslov">{infoProdavac.brTelefona}</p>
                <p className="brTelefonaP">{infoProdavac.brTelefonaValue}</p>
              </div>

              <div className="nazoviContainer">
                <a className="nazoviBtn" href="tel:+38269557371">
                  <img className="callIcon" src="/icons/call.png" /> Nazovi
                </a>
                <Link to="/poruka" className="porukaContainer">
                  <img className="porukaIcons" src="/icons/mess.png" />
                  <p>Poruka</p>
                </Link>
              </div>
              <Recenzije />
            </div>
            <div className="sticky">
              <div className={openPonuda ? "ponudaDiv" : "hide"}>
                <div className="ponudaDivInput">
                  <input
                    onChange={handleInputChange}
                    className={openPonuda ? "ponudaInput" : "hide"}
                    type="number"
                    placeholder="Unesite iznos"
                  />
                  <p className={errorPonuda ? "errorPonuda show" : "hide"}>
                    Unesite ponudu!
                  </p>
                  <p className="ponudaEuro">€</p>
                </div>

                <p className={closePonudaBtn ? "ponudaPoslata" : "hide"}>
                  Ponuda poslata!
                </p>
                <button onClick={closePonuda} className="posaljiPonudu">
                  Pošalji ponudu €
                </button>
              </div>
              <button
                onClick={clickPonuda}
                className={openPonuda ? "hide" : "posaljiPonudu"}
              >
                Pošalji ponudu €
              </button>
              <div className={`container2Ads pcVerision`}>
                <p className="osnovneInformacijeAds">Osnovne informacije</p>
                <div className="hr"></div>
                <div className="markaModelAds">
                  <p className="markaModelNaslov">{carAdInfo.marka}</p>
                  <p className="markaModelP">{carAdInfo.markaValue}</p>
                </div>
                <div className="markaModelAds">
                  <p className="markaModelNaslov">{carAdInfo.model}</p>
                  <p className="markaModelP">{carAdInfo.modelValue}</p>
                </div>
                <div className="cm3Ads">
                  <p className="cm3Naslov">{carAdInfo.kubikaza}</p>
                  <p className="cm3PAds">{carAdInfo.kubikazaValue}</p>
                </div>
                <div className="oznakaAds">
                  <p className="oznakaNaslov">{carAdInfo.oznaka}</p>
                  <p className="oznakaP">{carAdInfo.oznakaValue}</p>
                </div>{" "}
                <div className="karoserijaAds">
                  <p className="karoserijaNaslov">{carAdInfo.karoserija}</p>
                  <p className="karoserijaP">{carAdInfo.karoserijaValue}</p>
                </div>
                <div className="pogonAds">
                  <p className="pogonNaslov">{carAdInfo.pogon}</p>
                  <p className="pogonP">{carAdInfo.pogonValue}</p>
                </div>
                <div className="tabliceAds">
                  <p className="tabliceNaslov">{carAdInfo.tablice}</p>
                  <p className="tabliceP">{carAdInfo.tabliceValue}</p>
                </div>
                <div className="regAds">
                  <p className="regNaslov">{carAdInfo.registrovanDo}</p>
                  <p className="regP">{carAdInfo.registrovanDoValue}</p>
                </div>
                <div className="zamjenaAds">
                  <p className="zamjenaNaslov">{carAdInfo.zamjena}</p>
                  <p className="zamjenaP">{carAdInfo.zamjenaValue}</p>
                </div>
                <div className="ostecenjeAds">
                  <p className="ostecenjeNaslov">{carAdInfo.ostecenje}</p>
                  <p className="ostecenjeP">{carAdInfo.ostecenjeValue}</p>
                </div>
                <div className="brBrzinaAds">
                  <p className="brBrzinaNaslov">{carAdInfo.brojBrzina}</p>
                  <p className="brBrzinaP">{carAdInfo.brojBrzinaValue}</p>
                </div>
                <div className="brVrataAds">
                  <p className="brVrataNaslov">{carAdInfo.brojVrata}</p>
                  <p className="brVrataPOglas">{carAdInfo.brojBrzinaValue}</p>
                </div>
                <div className="bojaAds">
                  <p className="bojaNaslov">{carAdInfo.boja}</p>
                  <p className="bojaP">{carAdInfo.bojaValue}</p>
                </div>
              </div>
            </div>
          </div>
          <SlicniOglasi />
        </div>
      </div>
    </>
  );
};

export default Oglas;
