import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./pretragaPoFilterimaAutomobili.css";
import NoveObjave from "../../components/noveObjave/NoveObjave";
import HeaderPhone from "../../components/headerPhone/HeaderPhone";

import { GlobalContext } from "../../context/global";
import { useContext } from "react";
import { useEffect } from "react";
import TabBar from "../../components/Tabbar/Tabbar";
import PretragaProfil from "../../components/pretragaProfil/PretragaProfil";
import Menu from "../../components/menu/Menu";
export default function PretragaPoFilterimaAutomobili() {
  const [selectedSort, setSelectedSort] = useState("datumOpadajuce");
  const [openSort, setOpenSort] = useState(false);
  const [openUporedi, setOpenUporedi] = useState(false);
  const [openModify, setOpenModify] = useState(false);
  const { globalState: { filterSearch } = {}, setGlobalState } =
    useContext(GlobalContext);
  useEffect(() => {
    setOpenUporedi(false);
  }, []);

  const openFilters = () => {
    setGlobalState((prevState) => ({
      ...prevState,
      filterSearch: true,
    }));
  };

  const otvoriUporedi = () => {
    if (!openUporedi) {
      setOpenUporedi(true);
    } else setOpenUporedi(false);
  };

  const otvoriSort = () => {
    if (!openSort) {
      setOpenSort(true);
    } else setOpenSort(false);
  };

  const handleSortChange = (sortType) => {
    setSelectedSort(sortType);
  };

  const clickModifyData = () => {
    setOpenModify(true);
  };

  return (
    <div className="pretragaPoFilterimaContainer">
      <Menu />
      <div>
        <div className="headerPretragaDiv">
          <div className="headerPretraga">
            <h1 className="result1280">Pronađeno 45 oglasa</h1>
            <div onClick={openFilters} className="modify">
              <img className="modifyIcons" src="/icons/modify.png" />
              <p>Izmijeni podatke</p>
            </div>
            <div onClick={otvoriSort} className="sort relative">
              <p>Sortiraj</p>{" "}
              <img className="modifyIcons" src="/icons/sort.png" />
              <div className={openSort === true ? "sortirajPo" : "hide"}>
                <div onClick={() => handleSortChange("datumOpadajuce")}>
                  <p>Datum opadajuće</p>
                  <img
                    className={
                      selectedSort === "datumOpadajuce" ? "sortSelect" : "hide"
                    }
                    src="/icons/sortSelect.png"
                  />
                </div>
                <div onClick={() => handleSortChange("datumRastuce")}>
                  <p>Datum rastuće</p>
                  <img
                    className={
                      selectedSort === "datumRastuce" ? "sortSelect" : "hide"
                    }
                    src="/icons/sortSelect.png"
                  />
                </div>
                <div onClick={() => handleSortChange("cijenaOpadajuce")}>
                  <p>Cijena opadajuće</p>
                  <img
                    className={
                      selectedSort === "cijenaOpadajuce" ? "sortSelect" : "hide"
                    }
                    src="/icons/sortSelect.png"
                  />
                </div>
                <div onClick={() => handleSortChange("cijenaRastuce")}>
                  <p>Cijena rastuće</p>
                  <img
                    className={
                      selectedSort === "cijenaRastuce" ? "sortSelect" : "hide"
                    }
                    src="/icons/sortSelect.png"
                  />
                </div>
              </div>
            </div>
            <Link onClick={otvoriUporedi} to="" className="uporedi modify">
              <p>Uporedi vozila</p>
              <img className="uporediIcons" src="/icons/uporedi.png" />
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div></div>
        <NoveObjave
          dugmeUporedi={openUporedi.toString()}
          uporediAuto={openUporedi}
          showAll={true}
          naslov={
            openUporedi ? "Izaberite najviše 2 vozila" : "Pronađeno 45 oglasa"
          }
          openUporedi={openUporedi}
        />
      </div>
      <div className={filterSearch ? "hide" : "sdfsf"}>
        <TabBar
          home="/icons/home.svg"
          heart="/icons/heart.png"
          dodajOglas="/icons/dodajOglas.svg"
          poruke="/icons/poruke.svg"
          profil="/icons/podesavanja.svg"
        />
      </div>
    </div>
  );
}
