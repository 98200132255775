import React from "react";
import "./likedPost.css";

import { useState } from "react";
import NoveObjave from "../../components/noveObjave/NoveObjave";
import Tabbar from "../../components/Tabbar/Tabbar";
import Login from "../../pages/login/Login";
import TabBar from "../../components/Tabbar/Tabbar";
import Menu from "../menu/Menu";

const naslov = "Sačuvane objave";
const LikedPost = ({ showFavorited }) => {
  const nemaNalog = false;
  const nemaSacuvaneObjave = false;
  return (
    <div className="likedPost">
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heartt.png"
        addPostMenu="/icons/dodajOglas.svg"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/podesavanja.svg"
      />
      <div className={nemaNalog ? "nemaSacuvanih" : "hide"}>
        {nemaNalog && <Login />}
      </div>
      <div className={nemaSacuvaneObjave ? "nemaSacuvanihOglasa" : "hide"}>
        <p>Nemate sačuvanih oglasa</p>
      </div>

      {!nemaNalog && (
        <NoveObjave
          showAll="true"
          showFavorited={showFavorited}
          naslov={naslov}
        />
      )}
      <div className="tabbar">
        {" "}
        <Tabbar
          home="/icons/homee.svg"
          heart="/icons/heartt.png"
          dodajOglas="/icons/dodajOglas.svg"
          poruke="/icons/poruke.svg"
          profil="/icons/podesavanja.svg"
        />
      </div>
    </div>
  );
};

export default LikedPost;
