import React from "react";
import { Link } from "react-router-dom";
import "./istorijaPlacanja.css";
import Menu from "../../components/menu/Menu";

const placanja = [
  {
    id: 1,
    imgSrc: "/images/1.jpg",
    naslov: "Naslov oglasa",
    opis: "Sponzorisani oglas na 5 dana",
    cijena: "Cijena paketa 5 €"
  },
  {
    id: 2,
    imgSrc: "/images/1.jpg",
    naslov: "Naslov oglasa",
    opis: "Sponzorisani oglas na 5 dana",
    cijena: "Cijena paketa 15 €"
  },
  {
    id: 3,
    imgSrc: "/images/1.jpg",
    naslov: "Naslosdfv oglasa",
    opis: "Sponzordfisani oglas na 5 dana",
    cijena: "Cijena paketa 52 €"
  }
];

export default function IstorijaPlacanja() {
  return (
    <div className="istorijaPlacanja">
        <Menu
         homeMenu="/icons/homee.svg"
         likedMenu="/icons/heart.png"
         addPostMenu="/icons/dodajOglas.svg"
         porukeMenu="/icons/poruke.svg"
         profilMenu="/icons/profileSelect.png"
      />
      <div className="headerAktivni">
        <Link className="back" to="/profil">
          <img src="/icons/back.png" className="backBtn" alt="Back" />
        </Link>
        <img className="logo" src="/icons/logo.png" />
        <h1 className="naslovAktivniOglasi">Istorija plaćanja</h1>
      </div>
      <div>
        {placanja.map((placanje) => (
          <div key={placanje.id} className="istorijaPlacanjaDiv">
            <img className="statistikaOglasaImg" src={placanje.imgSrc} alt="Oglas" />
            <div className="opisPlacenogOglasa">
              <p>{placanje.naslov}</p>
              <p>{placanje.opis}</p>
              <p className="istorijaPlacanjaCijena">{placanje.cijena}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
