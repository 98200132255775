import React from "react";
import KarticaPodaci from "../../components/karticaPodaci/KarticaPodaci";

export default function KarticaPodaciOglas() {
  return (
    <div className="karticaPodaciOglas">
      <KarticaPodaci
        backTo="/izaberi-plan/nacin-placanja"
        oglasKartica="true"
      />
    </div>
  );
}
