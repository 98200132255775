import React, { useState } from "react";

import "./ostaliOglasi.css";
import Menu from "../../components/menu/Menu";
import NoveObjave from "../../components/noveObjave/NoveObjave";
import TabBar from "../../components/Tabbar/Tabbar";
import { useContext } from "react";
import { GlobalContext } from "../../context/global";

export default function OstaliOglasi() {
  const {
    globalState: { selectedCategory, openDetaljnaPretraga, filterSearch } = {},
    setGlobalState,
  } = useContext(GlobalContext);
  console.log(selectedCategory);
  const openFilters = () => {
    setGlobalState((prevState) => ({
      ...prevState,
      filterSearch: true,
    }));
  };
  return (
    <div className="ostaliOglasiContainer">
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heartt.png"
        addPostMenu="/icons/dodajOglas.svg"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/podesavanja.svg"
      />

      <NoveObjave
        reklamePocetna="true"
        naslov="Svi oglasi prodavca"
        showAll="true"
      />
      <div className={filterSearch ? "hide" : "sdfsf"}>
        <TabBar
          home="/icons/home.svg"
          heart="/icons/heart.png"
          dodajOglas="/icons/dodajOglas.svg"
          poruke="/icons/poruke.svg"
          profil="/icons/podesavanja.svg"
        />
      </div>
    </div>
  );
}
