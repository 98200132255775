import React from "react";
import { useParams } from "react-router-dom";
import "./statistika.css";
import { Link } from "react-router-dom";
import Menu from "../../components/menu/Menu";

export default function Statistika() {
  const dani = ["Pon", "Uto", "Sri", "Čet", "Pet", "Sub", "Ned"];
  const jazPregleda = Array.from(
    { length: 10 },
    (_, index) => 900 - index * 100
  );
  const limiti = Array.from({ length: 10 }, () => ({}));

  const podaci = [
    { naslov: "Ukupno aktivnih oglasa", broj: 5 },
    { naslov: "Ukupno pregleda na oglasima", broj: 2554 },
    { naslov: "Ukupno akcija", broj: 20 },
  ];

  const oglasi = [
    {
      naslov: "Volkswagen, Pasast CC",
      pregledi: 1456,
      akcije: 10,
      sponzorisano: "sponzorisanoPojedinacno",
    },
    {
      naslov: "Volkswagen, Pasast CC",
      pregledi: 1456,
      akcije: 10,
      sponzorisano: "sponzorisanoPojedinacno",
    },
    {
      naslov: "Volkswagen, Pasast CC",
      pregledi: 1436,
      akcije: 8,
      sponzorisano: "hide",
    },
  ];

  return (
    <div className="statistika">
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglas.svg"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/profileSelect.png"
      />
      <div>
        <div className="statistikaHeader">
          <Link className="back" to="/profil">
            <img src="/icons/back.png" className="backBtn" alt="Back" />
          </Link>
          <img className="logoDodajOglas" src="/icons/logo.png" alt="Logo" />
          <p className="statistikaNaslov">Statistika</p>
        </div>
        <div className="personalnaStatistika">
          <img className="personalImg" src="/images/1.jpg" />
          <p>Personalna statistika</p>
        </div>
        <div className="grafikon relative">
          <div className="borderLeft"></div>
          <div className="borderBottomGrafik"></div>
          <div className="dani">
            {dani.map((dan, index) => (
              <div key={index} className={dan.toLowerCase()}>
                <span>{dan}</span>
              </div>
            ))}
          </div>
          <div className="jazPregleda">
            {jazPregleda.map((jaz, index) => (
              <span key={index}>{jaz}</span>
            ))}
          </div>
          <div className="limiti">
            {limiti.map((limit, index) => (
              <div key={index} className="limit"></div>
            ))}
          </div>
        </div>
      </div>
      <div className="hr"></div>
      <div className="statistickiPodaci">
        {podaci.map(({ naslov, broj }, index) => (
          <div key={index}>
            <p>{naslov}</p>
            <p>{broj}</p>
          </div>
        ))}
      </div>
      <div className="pojedinacnaStatistika">
        <p className="statistikaOglasaNaslov">Statistika oglasa</p>
        {oglasi.map(({ naslov, pregledi, akcije, sponzorisano }, index) => (
          <div key={index} className="pojedinacnaStatistikaDiv">
            <img
              className="statistikaOglasaImg"
              src="/images/1.jpg"
              alt="Oglas"
            />
            <div className="divOglasPodaciPojedinacni">
              <p className="naslovPojedinacne">{naslov}</p>
              <div className="minDivStatsOglas">
                <p className="preglediPojedinacno">{pregledi} pregleda</p>
                <p className="akcijePojedinacno">{akcije} akcija</p>
                <p className={sponzorisano}>Sponzorisano</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
