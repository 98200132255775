import { Link } from "react-router-dom";
import React, { useState } from "react";
import "./izmijeni.css";
import Menu from "../../components/menu/Menu";

export default function Izmijeni() {
  const [selectedImage, setSelectedImage] = useState(null);
  const showProfilePicture = true;

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const userInfo = {
    brojTelefona: "434534",
  };
  return (
    <div>
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglas.svg"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/profileSelect.png"
      />
      <div className="izmijeni">
        <Link to="/profil" className="backk">
          <img src="/icons/back.png" className="backBtn" alt="Back" />
        </Link>

        <div className="izmijeniPodatke">
          <div className="profilnaEdit">
            <label className="labelUpload" htmlFor="uploadInput">
              <div className="profilnaSlika">
                {selectedImage ? (
                  <>
                    <img
                      className="profilnaSlika"
                      src={selectedImage}
                      alt="Profile"
                    />
                    <p className="addPhoto">+</p>
                  </>
                ) : (
                  <>
                    <img
                      className="profilnaSlika"
                      src="/images/pic.jpg"
                      alt="Profile"
                    />
                    <p className="addPhoto">+</p>
                  </>
                )}
              </div>
            </label>
            <input
              id="uploadInput"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </div>
          <p className="brojTelefonaUser">{userInfo.brojTelefona}</p>
        </div>
        <div className="kontaktIme">
          <p className="kontaktImeP">Kontakt ime</p>
          <input
            placeholder="Vaše ime"
            className="kontaktImeInput"
            type="text"
          />
        </div>
        <Link className="potvrdiIzmjene">Potvrdi</Link>
      </div>
    </div>
  );
}
