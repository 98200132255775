import "./pocetnaLogin.css";
import { Link } from "react-router-dom";

const socialLogins = [
  { className: "fb", text: "Nastavite sa", imgSrc: "/icons/fb.png" },
  { className: "ios", text: "Nastavite sa", imgSrc: "/icons/apple.png" },
  { className: "google", text: "Nastavite sa", imgSrc: "/icons/google.png" },
];

const callCodes = [{ value: "Arabic", text: "Arabic" }];

export default function HeaderPhone(obavezniPodatak) {
  return (
    <div className="pocetnaLogin">
      <div className="logoDiv">
        <img className="logo" src="/icons/logo.png" />
      </div>
      <div>
        <p className="telBr">Ulogujte se telefonskim brojem</p>
        <div className="telefonskiPodaci relative">
          <select className="pozivniBr">
            {callCodes.map((code, index) => (
              <option key={index} value={code.value}>
                {code.text}
              </option>
            ))}
          </select>
          <input
            className="brojTelefona"
            type="number"
            placeholder="6x xxx xxx"
          ></input>
        </div>
        <Link to="kod" className="ulogujSe">
          Uloguj se
        </Link>
        <div className="orDiv">
          <div className="hr"></div>
          <p className="orP">ili</p>
          <div className="hr"></div>
        </div>
        {socialLogins.map((social, index) => (
          <div key={index} className={`${social.className} socialDiv`}>
            <p className="nastaviteSocialP">{social.text}</p>
            <img className="loginWith" src={social.imgSrc} />
          </div>
        ))}
      </div>
    </div>
  );
}
