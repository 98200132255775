import React, { useState } from "react";
import "./kod.css";
import { Link } from "react-router-dom";

export default function Kod() {
  const [isResendKodHidden, setIsResendKodHidden] = useState(false);
  const [isPonovniKodShown, setIsPonovniKodShown] = useState(false);

  const handleResendKodClick = () => {
    setIsResendKodHidden(true);
    setIsPonovniKodShown(true);
  };
  return (
    <div className="kod">
      <Link to="/ulogovanje" className="back">
        <img src="/icons/back.png" alt="Back" className="backBtn" />
      </Link>
      <div>
        <p className="upisiKod">Upišite kod koji smo vam poslali:</p>
        <div className="inputiZaKod">
          <input className="inputKod"></input>
          <input className="inputKod"></input>
          <input className="inputKod"></input>
          <input className="inputKod"></input>
          <input className="inputKod"></input>
          <input className="inputKod"></input>
        </div>
        <a class="ulogujSe" href="/">
          Uloguj se
        </a>
        <Link
          className={`resendKod ${isResendKodHidden ? "hide" : ""}`}
          onClick={handleResendKodClick}
        >
          Nisam dobio kod
        </Link>
        <Link className={`ponovniKod ${isPonovniKodShown ? "show" : ""}`}>
          Kod je poslat pokušajte ponovo
        </Link>
      </div>
    </div>
  );
}
