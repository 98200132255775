import "./App.css";
import Kod from "./pages/kod/Kod";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Pocetna } from "./pages/pocetna/Pocetna";
import SacuvaniOglasi from "./pages/likes/SacuvaniOglasi";
import DodajOglas from "./pages/addPost/DodajOglas";

import Login from "./pages/login/Login";

import PretragaPoFilterimaAutomobili from "./pages/pretragaPoFilterimaAutomobili/PretragaPoFilterimaAutomobili";
import Profil from "./pages/profil/Profil";

import Izmijeni from "./pages/izmijeni/Izmijeni";

import Oglas from "./pages/oglas/Oglas";
import Poruka from "./pages/poruka/Poruka";

import Razgovori from "./pages/razgovori/Razgovori";
import Statistika from "./pages/statistika/Statistika";
import AktivniOglasi from "./pages/aktivniOglasi/AktivniOglasi";
import UObradi from "./pages/uObradi/UObradi";

import NacinPlacanja from "./components/nacinPlacanja/NacinPlacanja";
import IstorijaPlacanja from "./pages/istorijaPlacanja/IstorijaPlacanja";

import IzaberiPlan from "./pages/izaberiPlan/IzaberiPlan";
import Placanje from "./pages/placanje/Placanje";

import KarticaPodaciOglas from "./pages/karticaPodaciOglas/KarticaPodaciOglas";
import KarticaPodaciProfil from "./pages/karticaPodaciProfil/KarticaPodaciProfil";
import DodajNacinPlacanja from "./pages/dodajNacinPlacanja/DodajNacinPlacanja";

import UporedjivanjeVozila from "./pages/uporedjivanjeOglasa/UporedjivanjeOglasa";
import UporedjivanjeOglasa from "./pages/uporedjivanjeOglasa/UporedjivanjeOglasa";

import { GlobalProvider } from "./context/global";
import OstaliOglasi from "./pages/ostaliOglasiProdavca/ostaliOglasi";

function App() {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/poruka" element={<Poruka />} />
          <Route path="/poruke" element={<Razgovori />} />
          <Route path="/" element={<Pocetna />} />
          <Route path="/sacuvani-oglasi" element={<SacuvaniOglasi />} />
          <Route path="/dodaj-oglas" element={<DodajOglas />} />

          <Route path="/ulogovanje" element={<Login />} />
          <Route path="/ulogovanje/kod" element={<Kod />} />
          <Route path="/profil" element={<Profil />} />

          <Route path="/profil/izmijeni-podatke" element={<Izmijeni />} />
          <Route path="/oglas" element={<Oglas />} />
          <Route
            path="/pretraga-po-filterima"
            element={<PretragaPoFilterimaAutomobili />}
          />
          <Route path="/moji-oglasi/aktivni" element={<AktivniOglasi />} />
          <Route path="/moji-oglasi/u-obradi" element={<UObradi />} />
          <Route path="/oglasi-prodavca" element={<OstaliOglasi />} />
          <Route path="/dodaj-nacin-placanja" element={<NacinPlacanja />} />
          <Route path="/statistika" element={<Statistika />} />
          <Route
            path="/uporedjivanje-vozila"
            element={<UporedjivanjeOglasa />}
          />
          <Route
            path="/profil/dodaj-nacin-placanja"
            element={<DodajNacinPlacanja />}
          />
          <Route
            path="profil/istorija-placanja"
            element={<IstorijaPlacanja />}
          />
          <Route
            path="/profil/dodaj-nacin-placanja/kartice/"
            element={<KarticaPodaciProfil />}
          />
          <Route path="/izaberi-plan" element={<IzaberiPlan />} />
          <Route path="/izaberi-plan/nacin-placanja" element={<Placanje />} />
          <Route
            path="/izaberi-plan/nacin-placanja/kartice"
            element={<KarticaPodaciOglas />}
          />
        </Routes>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default App;
