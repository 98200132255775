import React, { useState } from "react";

import { Link } from "react-router-dom";
import "./placanje.css";
import NacinPlacanja from "../../components/nacinPlacanja/NacinPlacanja";

export default function Placanje() {
  const showObaveznoPolje = true;
  const da = true;
  return (
    <div className="placanje">
    <NacinPlacanja  link="/izaberi-plan" nacinPlacanja="Izaberi način plaćanja" dodajNacinPlacanja="true" platiKarticom="Plati karticom"/>
    </div>
  );
}
