import { Link } from "react-router-dom";
import "./butoniPretraga.css";

export default function ButoniPretraga({
  stilovi,
  pretraga,
  onClick,
  buttoniContainer,
  detaljnaPretraga,
  onClickDetaljna,
  funkcijaButtona,
}) {
  return (
    <>
      <div id={buttoniContainer} className="butoniPretraga">
        <Link
          onClick={onClickDetaljna}
          to={`${detaljnaPretraga}`}
          className={stilovi}
        >
          Detaljna pretraga
        </Link>
        <a onClick={onClick} href={`${pretraga}`} className="nastavi">
          {funkcijaButtona}
          <img className="nastaviStrelica" src="/icons/nastaviStrelica.png" />
        </a>
      </div>
    </>
  );
}
