import { Link } from "react-router-dom";
import "./headerPhone.css";
import { useState } from "react";
import NoveObjave from "../noveObjave/NoveObjave";
import PretragaProfil from "../pretragaProfil/PretragaProfil";

export default function HeaderPhone({filterKlikSearch}) {
  const [klikSearch, setKlikSearch] = useState(false);
  const [filterSearch, setFilterSearch] = useState(false);

 
  const kliknutoSearch = ()=>{
    setKlikSearch(true);
  }
  const showAll="true";
  
  const closeSearch = ()=>{
    setKlikSearch(false);
  }
  return (
    <div className="headPhone">
    
      <div className="search">
      
      
      
      
      
      <div className={klikSearch?"searchDiv long":"hide"}>
                            <div className="headSearch">
                              
                              <img onClick={closeSearch} className="backBtn" src="/icons/back.png"/>
                              <img className="logoHome" src="/icons/logo.png"/>
                              <p className="textPretraga">Pretraži</p>
                            </div>
          <div className="relative">                  <input
            placeholder="Pretraži"
            className="searchInput"
            type="text"
          />
          <div className="searchIconsDiv">
            <img className="searchIcons" src="/icons/search.png" />
          </div>
          </div>
       
        </div>





      
        <div onClick={kliknutoSearch} className={klikSearch?"hide":"searchDiv"}>
          <input
            placeholder="Pretraži"
            className="searchInput"
            type="text"
          />
          <div className="searchIconsDiv">
            <img className="searchIcons" src="/icons/search.png" />
          </div>
        </div>
        <img src="/icons/logoPocetna.png" className="logoHome" />
        <Link >
          <div onClick={filterKlikSearch} className="filterPretraga">
            <p className="pretragaP">Pratraga</p>
            <img className="filterIcons" src="/icons/filter.png" />
          </div>
        </Link>
      </div>
      
    </div>
  );
}
