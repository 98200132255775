import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import "./recenzije.css";
import Rating from "../../components/ratingStars/RatingStars";

const recenzijeKupaca = [
  {
    ime: "Dino Begović",
    datum: "April 6, 2024",
    ocjena: 4,
    komentar:
      "Bla blaaa bfdldbč fl ldfb ldf lbf Bla blaaa bfdldbč fl ldfb ldf lbf Bla blaaa bfdldbč fl ldfb ldf lbf",
    slika: "/images/dino.jpg",
  },
  {
    ime: "Dino Begović",
    datum: "April 6, 2024",
    ocjena: 2,
    komentar: "ŠTAAAAA JA ZNAAAAAAAAAAAAAAAAAAM",
    slika: "/images/dino.jpg",
  },
];

export default function Recenzije() {
  const [currentRating, setCurrentRating] = useState(null);
  const [errorStar, setErrorStar] = useState();
  const [comment, setComment] = useState("");
const [errorComent, setErrorComent] = useState();
const [errorNone, setErrorNone] = useState("");
const recenzijaDone = () => {
  if (!errorStar && !errorComent) {
    setErrorNone(true);
  
    setCurrentRating(null); 
    setComment(""); 
  } else {
    setErrorNone(false);
  }
};
  const handleRatingChangeInRecenzije = () => {
    if (currentRating === null) {
      setErrorStar(true);
    } else {
      setErrorStar(false);
    }
  };

  const handleCommentValidation = () => {
    if (comment.length < 5) {
      setErrorComent(true)
    } else {
      setErrorComent(false)
    }
  };

  return (
    <>
       <div className="recenzije">
      <div className="">
        <div className="recenzijeHeader">
          <p className="naslovRecenzije">Ostavite recenziju</p>
          <div className="relative">
            <Rating onRatingChange={setCurrentRating} />
            <p className={errorStar ? "errorStar" : "hide"}>Izaberite ocjenu.</p>
          </div>
        </div>
        <textarea
          className="komentar"
          placeholder="Ostavite komentar"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <p className={errorComent?"errorKoment":"hide"}>Unesite više od 5 slova.</p>
       <button onClick={() => {
  handleRatingChangeInRecenzije();
  handleCommentValidation();
  recenzijaDone();
}} className="recenzijaBtn">
  Ostavite recenziju
</button>
<p className={errorNone?"recenzijaDone":"hide"}>Uspješno ste poslali recenziju! ツ</p>
       
       
     
      </div>

</div>
      <div className="recenzijeKupaca">
        <p className="naslovRecenzije">Recenzije kupaca</p>
        {recenzijeKupaca.map((recenzija, index) => (
          <div key={index}>
            <div>
              <img src={recenzija.slika} className="recenzijaImg" alt={`Slika korisnika ${recenzija.ime}`} />
            </div>
            <div>
              <p className="recenzijaIme">{recenzija.ime}</p>
              <div className="recenzijaDivVrijeme">
                <p className="recenzijaDatum">{recenzija.datum}</p>
                {Array.from({ length: recenzija.ocjena }, (_, i) => (
                  <img
                    key={i}
                    className="recenzijaStar"
                    src={`/icons/rating${recenzija.ocjena}.png`}
                    alt="Ocjena"
                  />
                ))}
              </div>
              <p className="recenzijaKomentar">{recenzija.komentar}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
