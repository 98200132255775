import "./opis.css";
import React, { useState } from "react";
export default function Opis() {
  return (
    <>
      <div className="opis">
        <p className="obaveznoPolje">*</p>

        <div className="inputDivOpis">
          <textarea placeholder="Opis" className="inputOpis"></textarea>
          <p className="hide">Obavezno polje!</p>
        </div>

        <div className="kategorijaDiv">
          <p className="obaveznoPolje">*</p>
          <select className="kategorijaSelect">
            <option selected disabled>
              Zamjena
            </option>
          </select>
          <p className="hide">Obavezno polje!</p>
        </div>
      </div>
    </>
  );
}
