import "./dodatnaOprema.css";

export default function DodatnaOprema({ margine }) {
  const enterijerOprema = {
    "Alarmni sistem": false,
    "Ambijentalna rasvjeta": false,
    "Android Auto": false,
    "Apple CarPlay": false,
    "Naslon za ruke": false,
    "Grijanje volana": false,
    "Pristup invalidnim osobama": false,
    Bluetooth: false,
    "On-board kompjuter": false,
    "CD player": false,
    "Električni prozori": false,
    "Električno bočno ogledalo": false,
    "Električno podešavanje sedišta": false,
    "Električno podešavanje sedišta, pozadi": false,
    "Head-Up displej": false,
    "Indukcijsko punjenje za pametne telefone": false,
    "Auto unutrašnji retrovizor zatamnjivanje": false,
    "Kožni volan": false,
    "Masaža sjedišta": false,
    "Upozorenje na umor": false,
    "Multifunkcionalni volan": false,
    "Navigacijski sistem": false,
    "Sistem za hitne pozive": false,
    "Radio DAB": false,
    "Shift vesla": false,
    "Ventilacija sedišta": false,
    "Grijanje sedišta": false,
    "Grijanje sedišta na leđima": false,
    Ozvučenje: false,
    "Sportska sjedišta": false,
    "Glasovna kontrola": false,
    "Pomoćni grijač": false,
    "Ekran na dodir": false,
    "Tuner/Radio": false,
    TV: false,
    USB: false,
    "Potpuno digitalna instrument tabla": false,
    "Zimski paket": false,
    "WLAN / Wifi pristupna tačka": false,
  };
  const dodatnaOprema = {
    "Upozorenje o udaljenosti": false,
    "Prilagodljiva svjetla u krivinama": false,
    "Pomoćnik pri startu uzbrdo": false,
    "Bi-Xenon farovi": false,
    "Duga svjetla bez odsjaja": false,
    "Krovni nosači": false,
    "Električna vrata prtljažnika": false,
    "Električni imobilajzer": false,
    ESP: false,
    "Pomoćnik za duga svjetla": false,
    "Sistem ograničenja brzine": false,
    "Svetlo za krivine": false,
    "Lasersko svjetlo": false,
    "LED farovi": false,
    "LED dnevna svjetla": false,
    "Aluminijumske felne": false,
    "Svjetlosni senzor": false,
    "Vazdušno oslanjanje": false,
    "Asistent za noćno gledanje": false,
    "Svjetla za maglu": false,
    "Pomoćnik pri kočenju u slučaju nužde": false,
    "Rezervni točak": false,
    "Panorama krov": false,
    "Senzor za kišu": false,
    "Kontrola pritiska u gumama": false,
    Šiber: false,
    "Centralno zaključavanje bez ključa": false,
    "Servo upravljač": false,
    "Sportska suspenzija": false,
    "Sportski paket": false,
    "Pomoćnik za održavanje trake": false,
    "Automatski start/stop": false,
    "Pomoć za mrtvi ugao": false,
    "Kontrola vuče": false,
    "Prepoznavanje saobraćajnih znakova": false,
    "Xenon farovi": false,
    "Sistem centralnog zaključavanja": false,
  };
  return (
    <>
      <div id={margine} className="dodatneInfroContainer">
        <div class="horizontal-line"></div>
        <p class="dodatneInformacije">2. Dodatna oprema</p>
        <div className="dodatnaOprema">
          {Object.keys(dodatnaOprema).map((oprema) => (
            <div className="divOprema" key={oprema}>
              
              <input className="inputOprema" type="checkbox" id={oprema} />
              <label className="tekstOprema" htmlFor={oprema}>
                {oprema}
              </label>
            </div>
          ))}
        </div>
        <div class="horizontal-line"></div>
        <p className="unutrasnjaOpremaNaslov">3. Unutrašnja oprema</p>
        <div className="enterijer">
          {Object.keys(enterijerOprema).map((oprema) => (
            <div className="divOprema" key={oprema}>
              <input className="inputOprema" type="checkbox" id={oprema} />
              <label className="tekstOprema" htmlFor={oprema}>
                {oprema}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
