import React, { useLayoutEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs, Controller } from "swiper/modules";

import "./style.css";

export default function AdSwiper({ images = [], handleImageClick, ...args }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const [firstSwiper, setFirstSwiper] = useState();
  const [secondSwiper, setSecondSwiper] = useState();
  const swiper1Ref = useRef(null);
  const swiper2Ref = useRef();

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };

  useLayoutEffect(() => {
    if (swiper1Ref.current !== null) {
      swiper1Ref.current.controller.control = swiper2Ref.current;
    }
  }, []);

  return (
    <div className="h-[550px] ">
      <Swiper
        onSwiper={(swiper) => {
          if (swiper1Ref.current !== null) {
            swiper1Ref.current = swiper;
          }
        }}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
        preloadImages={false}
        controller={{ control: secondSwiper }}
        spaceBetween={10}
        slidesPerView={1}
        grabCursor={true}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs, Controller]}
        className="mySwiper"
      >
        <div className="indexSlike">
          <p className="slideInfo">
            {currentIndex + 1}/{images.length}
          </p>
        </div>
        <div className="velikaSlikaAds">
          {images?.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                className="carsImages"
                src={image}
                alt={`Slide ${index + 1}`}
                onClick={handleImageClick}
              />
            </SwiperSlide>
          ))}
        </div>

        <div className="swiper-button-prev">
          <p></p>
        </div>
        <div className="swiper-button-next"></div>
      </Swiper>
      <Swiper
        controller={{ control: firstSwiper }}
        loop={false}
        spaceBetween={10}
        slidesPerView={8}
        watchSlidesProgress
        touchRatio={0.2}
        preloadImages={false}
        lazy
        slideToClickedSlide={true}
        onSwiper={setThumbsSwiper}
        modules={[Navigation, Thumbs, Controller]}
        className="mySwiper"
      >
        <div className="tralallala">
          {images?.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                className="carsImages"
                src={image}
                alt={`Slide ${index + 1}`}
                onClick={handleImageClick}
              />
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
}
