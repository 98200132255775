import "./userInfo.css";
import { Link } from "react-router-dom";
export default function UserInfo({ hideElement }) {
  return (
    <div className="userInfo">
      <div className="podaciProfil">
        <div className="displayFlex">
          <div className="profilnaSlika">
            <img className="profilnaSlika" src="/images/pic.jpg" />
          </div>
          <div className="imeBroj">
            <p className="imeUser">Dino</p>
            <p className="brojTelefonaUser">15165165116</p>
          </div>
        </div>
        {hideElement && (
          <Link to="izmijeni-podatke" className="editDiv">
            <img className="editIcons" src="/icons/edit.png" />
            <p className="izmijeniP">Izmijeni</p>
          </Link>
        )}
      </div>
    </div>
  );
}
