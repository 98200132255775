import React from "react";
import "./uObradi.css";
import { Link } from "react-router-dom";
import Menu from "../../components/menu/Menu";

export default function UObradi() {
  const uObradiOglasi = [
    {
      id: 1,
      markaModel: "Marka, model",
      datum: "01.05.2023",
      slika: "/images/4.jpg",
      kategorija: "Kategorija",
      godiste: "Godište",
      kubikaza: "Kubikaža",
      cena: "10000 €",
    },

    {
      id: 2,
      markaModel: "Marka, model",
      datum: "01.05.2023",
      slika: "/images/4.jpg",
      kategorija: "Kategorija",
      godiste: "Godište",
      kubikaza: "Kubikaža",
      cena: "10000 €",
    },

    {
      id: 3,
      markaModel: "Marka, model",
      datum: "01.05.2023",
      slika: "/images/4.jpg",
      kategorija: "Kategorija",
      godiste: "Godište",
      kubikaza: "Kubikaža",
      cena: "10000 €",
    },
  ];

  return (
    <div className="aktivni">
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglas.svg"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/profileSelect.png"
      />
      <div className="headerAktivni">
        <Link className="back" to="/profil">
          <img src="/icons/back.png" className="backBtn" alt="Back" />
        </Link>
        <img className="logo" src="/icons/logo.png" />
        <h1 className="naslovAktivniOglasi">Oglasi u obradi</h1>
      </div>

      {uObradiOglasi.map((oglas) => (
        <div key={oglas.id} className="aktivniOglas">
          <div className="hr"></div>
          <div className="markaModelDatum">
            {" "}
            <p>Marka, model</p>
            <p>10.10.2023</p>
          </div>
          <div className="flex">
            <img className="imgMojiOglasi" src={oglas.slika} />
            <div className="atributiDiv">
              <p>{oglas.kategorija}</p>
              <p>{oglas.godiste}</p>
              <p>{oglas.kubikaza}</p>
              <p className="cijenaMojiOglasi">{oglas.cena}</p>
            </div>
          </div>

          <div className="mojiOglasiOpcije">
            <div>
              <img className="promovisiIcons" src="/icons/promovisi.png" />
              <p>Promoviši</p>
            </div>
            <div>
              <img className="promovisiIcons" src="/icons/editMojiOglasi.png" />
              <p>Izmijeni</p>
            </div>

            <div>
              <img className="promovisiIcons" src="/icons/delete.png" />
              <p>Izbriši</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
