import "./payment.css";
import { Link } from "react-router-dom";
export default function Payment( ) {
  return (
    <div className="mojiOglasi">
      <p className="mojiOglasiNaslov">Plaćanje</p>
      <div className="mojiOglasiLinkovi">
        <Link to="dodaj-nacin-placanja" className="statusOglasa">Dodaj način plaćanja</Link>

        
      </div>

      <div className="mojiOglasiLinkovi">
        <Link to="istorija-placanja" className="statusOglasa">Istorija plaćanja</Link>

   
      </div>

    </div>
  );
}
