import "./mojiOglasi.css";
import { Link } from "react-router-dom";
export default function MojiOglasi() {
  return (
    <div className="mojiOglasi">
      <p className="mojiOglasiNaslov">Moji oglasi</p>
      <div className="mojiOglasiLinkovi">
        <Link to="/moji-oglasi/aktivni" className="statusOglasa">Aktivni</Link>

        <p className="brojOglasa">1</p>
      </div>

      <div className="mojiOglasiLinkovi">
        <Link to="/moji-oglasi/u-obradi" className="statusOglasa">U obradi</Link>

        <p className="brojOglasa">1</p>
      </div>
      <div className="mojiOglasiLinkovi">
        <Link 
        to="/statistika"
        className="statusOglasa">Statistika</Link>

      </div>
    </div>
  );
}
