// Kategorije.jsx
import React from "react";
import { useEffect, useContext } from "react";
import { useState } from "react";
import "./kategorijePretraga.css";
import { Link } from "react-router-dom";
import TabBar from "../Tabbar/Tabbar";
import Menu from "../menu/Menu";
import AutoFilter from "../autoFilter/AutoFilter";

import { GlobalContext } from "../../context/global.js";

export default function KategorijePretraga({
  auto,
  motor,
  bus,
  kamion,
  prikolica,
  jahta,
  masine,
  traktor,
  tocak,
  mehanicar,
  detailing,
  styleKategorijeAuto,
  styleKategorijeMotor,
  pocetna,
  styleKategorijeBus,
  styleKategorijeKamion,
  styleKategorijePrikolica,
  styleKategorijeJahta,
  styleKategorijeMasine,
  styleKategorijeTraktor,
  styleKategorijeTocak,
  styleKategorijeMehanicar,
  styleKategorijeDetailing,
  LinkAuto,
  LinkMotor,
  LinkRent,
  styleKategorijeRent,
  rent,
  LinkBus,
  LinkKamion,
  LinkPrikolica,
  uporedi,
  LinkJahta,
  uslov,
  LinkMasine,
  LinkTraktor,
  LinkTocak,
  LinkMehanicar,
  LinkDetailing,
}) {
  const da = true;
  const ne = false;
  const [selectGume, setSelectGume] = useState(true);
  const [selectFelne, setSelectFelne] = useState(false);
  const [selectFelneSaGumama, setSelectFelneSaGumama] = useState(false);

  const { globalState: { selectedCategory } = {}, setGlobalState } =
    useContext(GlobalContext);

  const handleCategoryClick = (category) => {
    setGlobalState((prevState) => ({
      ...prevState,
      selectedCategory: category,
    }));
  };

  const kliknutoSelectGume = () => {
    setSelectFelne(false);
    setSelectFelneSaGumama(false);
    setSelectGume(true);
  };
  const kliknutoFelneSaGumama = () => {
    setSelectFelne(false);
    setSelectGume(false);
    setSelectFelneSaGumama(true);
  };
  const kliknutoSelectFelne = () => {
    setSelectGume(false);
    setSelectFelneSaGumama(false);
    setSelectFelne(true);
  };

  return (
    <div className="relative">
      <Menu
        homeMenu="/icons/home.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglas.svg"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/podesavanja.svg"
      />
      {pocetna && (
        <div className="pretragaBckg">
          <div
            className={
              selectedCategory === "auto" ? "pretraga1" : "pretraga1 hide"
            }
          >
            <AutoFilter
              markaSelect={da}
              modelSelect={da}
              styleOdDo="motoriDetaljnaCss"
              godiste={da}
              cijena={da}
              zamjena={da}
              kubikazaOdDo={da}
              kilometraza={da}
              karoserija={da}
              gorivo={da}
            />

            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>
          <div
            className={
              selectedCategory === "rent" ? "pretraga2" : "pretraga1 hide"
            }
          >
            <AutoFilter
              markaSelect={da}
              modelSelect={da}
              styleOdDo="motoriDetaljnaCss"
              godiste={da}
              cijena={da}
              mjenjac={da}
              kubikazaOdDo={da}
              kilometraza={da}
              karoserija={da}
              gorivo={da}
            />

            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>

          <div
            className={
              selectedCategory === "motor" ? "pretraga2" : "pretraga2 hide"
            }
          >
            <AutoFilter
              markaSelect={da}
              zamjena={da}
              styleOdDo="motoriDetaljnaCss"
              godiste={da}
              kubikazaOdDo={da}
              cijena={da}
              modelInput={da}
              tip={da}
              karoserija={da}
              gorivo={da}
            />

            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>
          <div
            className={
              selectedCategory === "bus" ? "pretraga2" : "pretraga1 hide"
            }
          >
            <AutoFilter
              markaSelect={da}
              modelInput={da}
              karoserija={da}
              gorivo={da}
              styleOdDo="motoriDetaljnaCss"
              zamjena={da}
              godiste={da}
              cijena={da}
              kubikazaOdDo={da}
              brSjedista={da}
            />

            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>
          <div
            className={
              selectedCategory === "prikolica"
                ? "pretraga2 pretraga2Prikolica"
                : "pretraga1 hide"
            }
          >
            <AutoFilter
              cijena={da}
              zamjena={da}
              markaSelect={da}
              styleOdDo="motoriDetaljnaCss"
              duzinaOdDo={da}
              sirinaOdDo={da}
              godiste={da}
              modelInput={da}
              karoserija={da}
              nosivost={da}
            />
            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>
          <div
            className={
              selectedCategory === "kamion"
                ? "pretraga2 pretraga2Big"
                : "pretraga1 hide"
            }
          >
            <AutoFilter
              markaSelect={da}
              modelInput={da}
              tip={da}
              kilovati={da}
              nosivost={da}
              karoserija={da}
              gorivo={da}
              zamjena={da}
              godiste={da}
              cijena={da}
              kubikazaOdDo={da}
              kilometraza={da}
              styleOdDo="motoriDetaljnaCss"
            />
            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>
          <div
            className={
              selectedCategory === "masine" ? "pretraga2" : "pretraga1 hide"
            }
          >
            <AutoFilter
              styleOdDo="motoriDetaljnaCss"
              godiste={da}
              brSati={da}
              zamjena={da}
              cijena={da}
              kubikazaOdDo={da}
              markaSelect={da}
              modelInput={da}
              tip={da}
              gorivo={da}
            />
            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>
          <div
            className={
              selectedCategory === "traktor" ? "pretraga2" : "pretraga1 hide"
            }
          >
            <AutoFilter
              markaSelect={da}
              modelInput={da}
              tip={da}
              gorivo={da}
              zamjena={da}
              cijena={da}
              styleOdDo="motoriDetaljnaCss"
              godiste={da}
              brSati={da}
              kubikazaOdDo={da}
            />
            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>
          <div
            className={
              selectedCategory === "jahta"
                ? "pretraga2 pretraga2Big"
                : "pretraga1 hide"
            }
          >
            <AutoFilter
              markaSelect={da}
              modelInput={da}
              karoserija={da}
              gorivo={da}
              tip={da}
              zamjena={da}
              sirinaOdDo={da}
              cijena={da}
              styleOdDo="motoriDetaljnaCss"
              godiste={da}
              materijalSelect={da}
              duzinaOdDo={da}
              brSati={da}
            />
            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>
          <div
            className={selectedCategory === undefined ? "pretraga4" : "hide"}
          >
            <AutoFilter
              markaSelect={da}
              modelSelect={da}
              styleOdDo="motoriDetaljnaCss"
              godiste={da}
              cijena={da}
              zamjena={da}
              kubikazaOdDo={da}
              kilometraza={da}
              karoserija={da}
              gorivo={da}
            />

            <Link to="/pretraga-po-filterima" className="btnBckg">
              Pretraži <img className="searchBckg" src="/icons/search.png" />
            </Link>
          </div>

          <div
            className={
              selectedCategory === "tocak" ? "pretraga6" : "pretraga6 hide"
            }
          >
            <div className="gumefelne">
              <p
                onClick={kliknutoSelectGume}
                className={selectGume ? "gumeP selectedGumeFelne" : "gumeP"}
              >
                Gume
              </p>
              <p
                onClick={kliknutoFelneSaGumama}
                className={
                  selectFelneSaGumama
                    ? "felneSaGumama selectedGumeFelne"
                    : "felneSaGumama"
                }
              >
                Felne sa gumama
              </p>
              <p
                onClick={kliknutoSelectFelne}
                className={selectFelne ? "felneP selectedGumeFelne" : "felneP"}
              >
                Felne
              </p>
            </div>

            <div className={selectGume ? "tocak1" : "hide"}>
              <AutoFilter
                komada={da}
                sezona={da}
                visina={da}
                sirinaGume={da}
                tipVozila={da}
                markaSelect={da}
                modelInput={da}
                godiste={da}
                cijena={da}
              />

              <button className="btnBckg">
                Pretraži <img className="searchBckg" src="/icons/search.png" />
              </button>
            </div>
            <div className={selectFelne ? "tocak2" : "hide"}>
              <AutoFilter
                godiste={da}
                markaSelect={da}
                modelInput={da}
                materijalFelne={da}
                komada={da}
                rasponRupa={da}
                tipVozila={da}
                velicina={da}
                cijena={da}
              />
              <button className="btnBckg">
                Pretraži <img className="searchBckg" src="/icons/search.png" />
              </button>
            </div>
            <div className={selectFelneSaGumama ? "tocak3" : "hide"}>
              <AutoFilter
                godiste={da}
                visina={da}
                velicina={da}
                komada={da}
                markaSelect={da}
                modelInput={da}
                sirinaGume={da}
                rasponRupa={da}
                cijena={da}
              />
              <button className="btnBckg">
                Pretraži <img className="searchBckg" src="/icons/search.png" />
              </button>
            </div>
          </div>
        </div>
      )}
      {pocetna && <img className="bckgImg" src="/images/bckg.avif" />}

      <div className={uslov ? "hide" : "headerPretragaKategorija"}>
        <p className="naslovPretrageKategorija">
          {uporedi === true ? "Pretražite drugo vozilo" : "Pretraži"}
        </p>
        <img className="deleteIcons" src="/icons/delete.png" />
      </div>
      <div className="divKategorijaPretraga">
        <Link
          to={uslov ? "" : "/pretraga-filter/automobili/"}
          className={LinkAuto}
          onClick={() => handleCategoryClick("auto")}
        >
          <div
            className={
              selectedCategory === "auto"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeAuto}
              src={
                selectedCategory === "auto" ? "/images/autoSelected.png" : auto
              }
            />
            <p>Automobili</p>
          </div>
          <div className="borderRight"></div>
        </Link>

        <Link
          to={uslov ? "" : "/pretraga-filter/motori/"}
          className={LinkMotor}
          onClick={() => handleCategoryClick("motor")}
        >
          <div
            className={
              selectedCategory === "motor"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeMotor}
              src={
                selectedCategory === "motor"
                  ? "/images/motorSelected.png"
                  : motor
              }
            />
            <p>Motori</p>
          </div>
          <div className="borderRight"></div>
        </Link>
        <Link
          to={uslov ? "" : "/pretraga-filter/autobusi/"}
          className={LinkBus}
          onClick={() => handleCategoryClick("bus")}
        >
          <div
            className={
              selectedCategory === "bus"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeBus}
              src={selectedCategory === "bus" ? "/images/busSelected.png" : bus}
            />

            <p>Autobusi</p>
          </div>
          <div className="borderRight"></div>
        </Link>
        <Link
          to={uslov ? "" : "/pretraga-filter/kamioni/"}
          className={LinkKamion}
          onClick={() => handleCategoryClick("kamion")}
        >
          <div
            className={
              selectedCategory === "kamion"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeKamion}
              src={
                selectedCategory === "kamion"
                  ? "/images/kamionSelected.png"
                  : kamion
              }
            />
            <p>Transporti</p>
          </div>
          <div className="borderRight"></div>
        </Link>
        <Link
          to={uslov ? "" : "/pretraga-filter/iznajmi-vozilo/"}
          className={LinkRent}
          onClick={() => handleCategoryClick("rent")}
        >
          <div
            className={
              selectedCategory === "rent"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeRent}
              src={
                selectedCategory === "rent" ? "/icons/selectedRent.svg" : rent
              }
            />
            <p>Iznajmi vozilo</p>
          </div>
          <div className="borderRight"></div>
        </Link>
        <Link
          to={uslov ? "" : "/pretraga-filter/prikolice/"}
          className={LinkPrikolica}
          onClick={() => handleCategoryClick("prikolica")}
        >
          <div
            className={
              selectedCategory === "prikolica"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijePrikolica}
              src={
                selectedCategory === "prikolica"
                  ? "/images/prikolicaSelected.png"
                  : prikolica
              }
            />
            <p>Prikolice</p>
          </div>
          <div className="borderRight"></div>
        </Link>
        <Link
          to={uslov ? "" : "/pretraga-filter/nautika/"}
          className={LinkJahta}
          onClick={() => handleCategoryClick("jahta")}
        >
          <div
            className={
              selectedCategory === "jahta"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeJahta}
              src={
                selectedCategory === "jahta"
                  ? "/images/jahtaSelected.png"
                  : jahta
              }
            />
            <p>Plovidbe</p>
          </div>
          <div className="borderRight"></div>
        </Link>
        <Link
          to={uslov ? "" : "/pretraga-filter/gradjevinske-masine/"}
          className={LinkMasine}
          onClick={() => handleCategoryClick("masine")}
        >
          <div
            className={
              selectedCategory === "masine"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeMasine}
              src={
                selectedCategory === "masine"
                  ? "/images/masineSelected.png"
                  : masine
              }
            />
            <p>Građevinske mašine</p>
          </div>
          <div className="borderRight"></div>
        </Link>
        <Link
          to={uslov ? "" : "/pretraga-filter/poljoprivredne-masine/"}
          className={LinkTraktor}
        >
          <div
            className={
              selectedCategory === "traktor"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeTraktor}
              src={
                selectedCategory === "traktor"
                  ? "/images/traktorSelected.png"
                  : traktor
              }
              onClick={() => handleCategoryClick("traktor")}
            />
            <p>Poljoprivredne mašine</p>
          </div>
          <div className="borderRight"></div>
        </Link>
        <Link
          to={uslov ? "" : "/pretraga-filter/felne-gume/"}
          className={LinkTocak}
        >
          <div
            className={
              selectedCategory === "tocak"
                ? "nazivKategorija selectedColor"
                : "nazivKategorija"
            }
          >
            <img
              className={styleKategorijeTocak}
              src={
                selectedCategory === "tocak"
                  ? "/images/tocakSelected.png"
                  : tocak
              }
              onClick={() => handleCategoryClick("tocak")}
            />
            <p>Felne / gume</p>
          </div>
          <div className="borderRight"></div>
        </Link>
      </div>
    </div>
  );
}
