import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Tabbar from "../../components/Tabbar/Tabbar";
import UserInfo from "../../components/userInfo/UserInfo";
import MojiOglasi from "../../components/mojiOglasi/MojiOglasi";
import "./profil.css";
import Payment from "../../components/payment/Payment";
import Login from "../login/Login";
import Menu from "../../components/menu/Menu";
import { Pocetna } from "../pocetna/Pocetna";
export default function Profil() {
  const [odjaviSe, setOdjaviSe] = useState(false);
  const [closeOdjaviSe, setCloseOdjaviSe] = useState(false);
  const [language, setLanguage] = useState("Crnogorski");
  const [jezik, setJezik] = useState(false);
  const otkaziOdjavu = () => {
    setCloseOdjaviSe(true);
  };
  const promeniJezik = (noviJezik) => {
    setLanguage(noviJezik);
    setJezik(false);
  };
  const clickJezik = () => {
    setJezik(!jezik);
  };
  const kliknutoOdjaviSe = () => {
    setOdjaviSe(!odjaviSe);
    setCloseOdjaviSe(false);
  };
  const nemaNalog = false;
  return (
    <>
      {" "}
      <div className="profil">
        <Menu
          homeMenu="/icons/homee.svg"
          likedMenu="/icons/heart.png"
          addPostMenu="/icons/dodajOglas.svg"
          porukeMenu="/icons/poruke.svg"
          profilMenu="/icons/profileSelect.png"
        />
        <div></div>
        {nemaNalog && <Login />}
        {!nemaNalog && (
          <div>
            {" "}
            <UserInfo hideElement="true" />
            <div className="hrr"></div>
            <MojiOglasi />
            <div className="hrr"></div>
            <Payment />
            <div className="hrr"></div>
            <div className="mojiOglasi">
              <p className="mojiOglasiNaslov">Opšte</p>
              <div className="mojiOglasiLinkovi">
                <Link className="statusOglasa">Uslovi korišćenja</Link>
              </div>
              <div
                className={
                  jezik
                    ? "mojiOglasiLinkovi paddingJezik"
                    : "mojiOglasiLinkovi jezikLink"
                }
              >
                <Link onClick={clickJezik} className="statusOglasa">
                  Jezik
                </Link>

                <div className={jezik ? "selectLanguageProfil" : "hide"}>
                  <img
                    className={jezik ? "closeLanguageProfil" : "hide"}
                    onClick={clickJezik}
                    src="/icons/closeMenu.png"
                  />
                  <img
                    className="jeziciIcons"
                    src="/icons/me.png"
                    onClick={() => promeniJezik("Crnogorski")}
                  />
                  <p onClick={() => promeniJezik("Crnogorski")}>Crnogorski</p>
                  <img
                    className="jeziciIcons"
                    src="/icons/engleska.png"
                    onClick={() => promeniJezik("English")}
                  />
                  <p onClick={() => promeniJezik("English")}>English</p>
                  <img
                    className="jeziciIcons"
                    src="/icons/rusija.png"
                    onClick={() => promeniJezik("Pусский")}
                  />
                  <p onClick={() => promeniJezik("Pусский")}>Pусский</p>
                  <img
                    className="jeziciIcons"
                    src="/icons/turska.png"
                    onClick={() => promeniJezik("Türkçe")}
                  />
                  <p onClick={() => promeniJezik("Türkçe")}>Türkçe</p>
                </div>
              </div>
              <div className="mojiOglasiLinkovi">
                <Link onClick={kliknutoOdjaviSe} className="statusOglasa">
                  Odjavi se
                </Link>
              </div>
              <div className={closeOdjaviSe ? "hideProfil" : ""}>
                <div
                  onClick={otkaziOdjavu}
                  id="showProfil"
                  className={odjaviSe ? "zatamniProfil" : "hide"}
                >
                  {" "}
                </div>
                <div
                  id="showProfil"
                  className={jezik ? "zatamniProfil" : "hide"}
                >
                  {" "}
                </div>

                <div className={odjaviSe ? "odjavaAsk" : "hide"}>
                  <p>Da li ste sigurnni da želite da se odjavite?</p>
                  <div>
                    <Link to="/" className="odjavaDa">
                      Da
                    </Link>
                    <Link onClick={otkaziOdjavu} className="odjavaNe">
                      Ne
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Tabbar
          home="/icons/homee.svg"
          heart="/icons/heart.png"
          dodajOglas="/icons/dodajOglas.svg"
          poruke="/icons/poruke.svg"
          profil="/icons/profileSelect.png"
        />
      </div>
      <div className="profilPocetna">
        <Pocetna />
      </div>
    </>
  );
}
