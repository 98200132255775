import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserInfo from "../../components/userInfo/UserInfo";
import "./poruka.css";
import Menu from "../../components/menu/Menu";

export default function Poruka() {
  const [currentDate, setCurrentDate] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [poruke, setPoruke] = useState([]);
  const [kliknuto, setKliknuto] = useState("");
  useEffect(() => {
    const getCurrentDate = () => {
      const now = new Date();
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: "Europe/Belgrade",
        localeMatcher: "best fit",
        hour12: false,
      };
      const formattedDate = now.toLocaleDateString("sr-Latn-RS", options);
      setCurrentDate(formattedDate);
    };

    getCurrentDate();
  }, []);

  const kliknutiMenu = () => {
    if (!kliknuto) {
      setKliknuto(true);
    } else setKliknuto(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendClick = () => {
    if (inputValue.trim() !== "") {
      setPoruke([...poruke, inputValue]);
      setInputValue("");
    }

  };

  return (<div className="porukaComponentes">   <Menu
    homeMenu="/icons/homee.svg"
    likedMenu="/icons/heart.png"
    addPostMenu="/icons/dodajOglas.svg"
    porukeMenu="/icons/porukeSelected.png"
    profilMenu="/icons/podesavanja.svg"
   />
    <div className="porukaCaskanje">
        
      <div className="poruka">
        <div className="porukeHeader1">
        <Link to="/poruke">
            <img className="backBtn" src="/icons/back.png" alt="Back" />
          </Link>
          <div className="displayFlex">
          <div className="profilnaSlika">
            <img className="profilnaChat" src="/images/pic.jpg" />
          </div>
          <div className="imeBroj">
            <p className="imeUser">Dino</p>
            <p className="brojTelefonaUser">15165165116</p>
          </div>
        </div>
        </div><div className="backAndMenu">
       
        <img
          className="menuMessBtn"
          src="/icons/menuPoruke.png"
          alt="Menu"
          onClick={kliknutiMenu}
        />
        </div>
        <div className={`menuOpcije${kliknuto ? " menuOpcijeFlex" : ""}`}>
          <p>Izbriši</p>
          <p>Blokiraj</p>
        </div>
      </div>
      <div className="containerRazgovor">
        <p className="date">{currentDate}</p>
        <div className="razgovor">
          {poruke.map((poruka, index) => (
            <p key={index} className="poslataPoruka">
              {poruka}
            </p>
          ))}
        </div>
        <div className="primljenePoruke">
          <p className="inboxPoruke"></p>
        </div>
      </div>
      <div className="divTekstPoruke">
        <input
          className="inputPoruka"
          placeholder="Unesite poruku"
          value={inputValue}
          onChange={handleInputChange}
        />
        <div className="divSendBtn" onClick={handleSendClick}>
          <img className="sendBtn" src="/icons/send.png" alt="Send" />
        </div>
      </div>
    </div>
    </div>
  );
}
