import { Link } from "react-router-dom";
import { useState } from "react";
import Cards from "react-credit-cards";
import {
  formatCreditCardNumber,
  formatCVV,
  formatExpirationDate,
} from "../../utils/index.js";
import "./karticaPodaci.css";
import "react-credit-cards/es/styles-compiled.css";
import Menu from "../../components/menu/Menu.js";

export default function KarticaPodaci({
  profilKartica,
  ukupnaCijena,
  oglasKartica,
  backTo,
}) {
  const [podaci, setPodaci] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (event, key) => {
    let value = event.target.value;
    const formatters = {
      brojKartice: formatCreditCardNumber,
      validnaDo: formatExpirationDate,
      cvv: formatCVV,
    };
    if (formatters[key]) value = formatters[key](value);
    setPodaci((prevPodaci) => ({ ...prevPodaci, [key]: value }));
  };

  const handleFocus = ({ target }) => {
    setPodaci((prevPodaci) => ({ ...prevPodaci, focused: target.name }));
  };

  const handleSendMessage = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
      window.location.href = "/";
    }, 3000);
  };

  return (
    <div className="karticaPodaci">
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglas.svg"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/profileSelect.png"
      />
      {showSuccessMessage && (
        <div className="successMessage">
          <div>
            <img className="allDone" src="/images/allDone.png" />
            <img className="vector" src="/images/vector.png" />
            <p>Uspješno ste poslali oglas!</p>
          </div>
        </div>
      )}
      <div className="headerAktivni">
        <Link className="back" to={backTo}>
          <img src="/icons/back.png" className="backBtn" alt="Back" />
        </Link>
        <img className="logo" src="/icons/logo.png" alt="Logo" />
        <h1 className="naslovAktivniOglasi">Unesite podatke</h1>
      </div>
      <Cards
        cvc={podaci.cvc || ""}
        expiry={podaci.validnaDo || ""}
        focused={podaci.focused || ""}
        name={podaci.imePrezime || ""}
        number={podaci.brojKartice || ""}
      />
      <div className="podaciKartice">
        <div className="brKar">
          <label>Broj kartice:</label>
          <input
            placeholder="xxxx xxxx xxxx xxxx"
            onFocus={handleFocus}
            value={podaci.brojKartice || ""}
            onChange={(e) => handleChange(e, "brojKartice")}
          />
        </div>
        <div className="imeKar">
          <label>Vaše ime:</label>
          <input
            placeholder="xxxx xxxxxxx"
            onFocus={handleFocus}
            value={podaci.imePrezime || ""}
            onChange={(e) => handleChange(e, "imePrezime")}
          />
        </div>
        <div className="vrijemeCVC">
          <div>
            <label>Validna do:</label>
            <input
              placeholder="xx/xx"
              onFocus={handleFocus}
              value={podaci.validnaDo || ""}
              onChange={(e) => handleChange(e, "validnaDo")}
            />
          </div>
          <div>
            <label>CVC:</label>
            <input
              placeholder="xxx"
              onFocus={handleFocus}
              value={podaci.cvc || ""}
              onChange={(e) => handleChange(e, "cvc")}
            />
          </div>
        </div>
      </div>
      {oglasKartica && (
        <div className="footerPlacanje">
          <div>
            <p className="ukupnoKartice">Ukupno: </p>
            <p className="ukupnaCijena">10€</p>
          </div>
          <button onClick={handleSendMessage} className="platiKartica">
            Plati
          </button>
        </div>
      )}
      {profilKartica && (
        <button className="platiKartica">Dodaj način plaćanja</button>
      )}
    </div>
  );
}
