import React, { useEffect, useState } from "react";
import "./tabbar.css";
import { Link } from "react-router-dom";
const TabBar = ({ home, heart, dodajOglas, poruke, profil }) => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;

      if (isScrollingUp) {
        document.querySelector(".tabbar").classList.remove("hidden");
      } else {
        document.querySelector(".tabbar").classList.add("hidden");
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className={`tabbar ${prevScrollPos === 0 ? "" : "hidden"}`}>
      <div>
        <div>
          <Link to="/">
            <img className="ikonicaTabbar" src={home} />
            <p className="tabbarP">Početna</p>
          </Link>
        </div>
      </div>
      <div>
        <div>
          <Link to="/sacuvani-oglasi">
            <img className="ikonicaTabbar" src={heart} />
            <p className="tabbarP">Sačuvani oglasi</p>
          </Link>
        </div>
      </div>
      <div>
        <div>
          <Link to="/dodaj-oglas">
            <img className="ikonicaTabbar" src={dodajOglas} />
            <p className="tabbarP">Dodaj oglas</p>
          </Link>
        </div>
      </div>
      <div>
        <div>
          <Link to="/poruke">
            <img className="ikonicaTabbar" src={poruke} />
            <p className="tabbarP">Poruke</p>
          </Link>
        </div>
      </div>
      <div>
        <div>
          <Link to="/profil">
            <img className="ikonicaTabbar" src={profil} />
            <p className="tabbarP">Profil</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TabBar;
