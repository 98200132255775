import React, { useState, createContext } from "react";

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    sidebarOpened: false,
    openDetaljnaPretraga: false,
    openFilter: false,
    kategorija: "",
    openAddPostSearch: false,
  });

  const kliknutaKategorija = (zadataKategorija) => {
    setState((prevState) => ({
      ...prevState,
      kategorija: zadataKategorija,
    }));
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      openAddPostSearch: true,
    }));
  };

  return (
    <GlobalContext.Provider
      value={{
        globalState: state,
        setGlobalState: setState,
        kliknutaKategorija,
        handleButtonClick,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
