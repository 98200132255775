import React, { useState } from "react";
import Tabbar from "../../components/Tabbar/Tabbar";
import { Link } from "react-router-dom";
import "./razgovori.css";
import AutoFilter from "../../components/autoFilter/AutoFilter";

import Inbox from "../../components/inbox/Inbox";
import Login from "../login/Login";
import Menu from "../../components/menu/Menu";



export default function Razgovori() {
  const showObaveznoPolje = true;
  const da = true;
  const nemaNalog = false;
  const poruke = true;
  return (
   <div className="razgovoriContainer"> <div className="razgovori">
    <Menu
     homeMenu="/icons/homee.svg"
     likedMenu="/icons/heart.png"
     addPostMenu="/icons/dodajOglas.svg"
     porukeMenu="/icons/porukeSelected.png"
     profilMenu="/icons/podesavanja.svg"
    />
       {nemaNalog &&   <Login/>}
      <div className={poruke?"hide":"showNemaPoruka"}>
        <p>Trenutno nemate poruka</p>
      </div>

       {!nemaNalog &&   <Inbox/>}
      

    </div>
        <Tabbar
          home="/icons/homee.svg"
          heart="/icons/heart.png"
          dodajOglas="/icons/dodajOglas.svg"
          poruke="/icons/porukeSelected.png"
          profil="/icons/podesavanja.svg"
        />

</div>        );
}
