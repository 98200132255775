import React, { useState } from "react";
import "./addPostSearch.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../context/global";
import Menu from "../menu/Menu";
import AutoFilter from "../autoFilter/AutoFilter";
import DodatnaOprema from "../dodatnaOprema/DodatnaOprema";
import ButoniPretraga from "../../components/butoniPretraga/ButoniPretraga";
import TabBar from "../Tabbar/Tabbar";
import LogoutDodajOglas from "../logoutDodajOglas/LogoutDodajOglas";

export default function AddPostSearch({ closeButtonDodajOglas }) {
  const { globalState, kliknutaKategorija } = useContext(GlobalContext);
  const { kategorija } = globalState;
  const da = true;
  const nemaNalog = true;

  return (
    <div className="containerAddPostSearch">
      <div
        className={
          kategorija === "Automobil" || kategorija === "Rent" ? "" : "hide"
        }
      >
        <>
          <div className="automobiliDodajOglasContainer">
            <div className="atributi">
              <AutoFilter
                backDodajOglas={closeButtonDodajOglas}
                srcDodajOglasIkonica="/images/autoSelect.png"
                kategorijaNaslovaDodajOglas="Automobil"
                dodajOglas={da}
                showObaveznoPolje={da}
                markaSelect={da}
                modelSelect={da}
                oznaka={da}
                kmDodajOglas={da}
                ksDodajOglas={da}
                kwDodajOglas={da}
                cm3DodajOglas={da}
                obavezniPodatak={da}
                godisteDodajOglas={da}
                karoserija={da}
                gorivo={da}
                stanje={da}
                boja={da}
                brBrzinaMjenjaca={da}
                mjenjac={da}
                brVrata={da}
                pogon={da}
                ostecenje={da}
                registracija={da}
                tablice={da}
              />
            </div>
            <DodatnaOprema margine="margine" />
          </div>
          <ButoniPretraga
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />{" "}
          <div className="hide777">
            <TabBar
              home="/icons/homee.svg"
              heart="/icons/heart.png"
              dodajOglas="/icons/dodajOglass.png"
              poruke="/icons/poruke.svg"
              profil="/icons/podesavanja.svg"
            />
          </div>
        </>
      </div>
      <div className={kategorija === "Motor" ? "" : "hide"}>
        <div className="automobiliDodajOglasContainer">
          <div className="motoriDodajOglas">
            <div className="atributi">
              <AutoFilter
                backDodajOglas={closeButtonDodajOglas}
                srcDodajOglasIkonica="/images/motorSelect.png"
                kategorijaNaslovaDodajOglas="Motori"
                dodajOglas={da}
                showObaveznoPolje={da}
                markaSelect={da}
                modelInput={da}
                kmDodajOglas={da}
                ksDodajOglas={da}
                kwDodajOglas={da}
                cm3DodajOglas={da}
                obavezniPodatak={da}
                godisteDodajOglas={da}
                karoserija={da}
                gorivo={da}
                pogon={da}
                brBrzinaMjenjaca={da}
                stanje={da}
                boja={da}
                mjenjac={da}
                ostecenje={da}
                registracija={da}
                tablice={da}
              />
            </div>

            {nemaNalog && <LogoutDodajOglas />}
          </div>
          <ButoniPretraga
            funkcijaButtona="Dodaj oglas"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />
          <div className="hide777"></div>
        </div>
      </div>
      <div className={kategorija === "Kamion" ? "" : "hide"}>
        {" "}
        <div className="automobiliDodajOglasContainer">
          <div className="atributi">
            <AutoFilter
              backDodajOglas={closeButtonDodajOglas}
              srcDodajOglasIkonica="/images/kamionSelect.png"
              kategorijaNaslovaDodajOglas="Transport"
              dodajOglas={da}
              showObaveznoPolje={da}
              markaSelect={da}
              modelInput={da}
              tip={da}
              kmDodajOglas={da}
              ksDodajOglas={da}
              kwDodajOglas={da}
              cm3DodajOglas={da}
              nosivost={da}
              obavezniPodatak={da}
              godisteDodajOglas={da}
              karoserija={da}
              gorivo={da}
              stanje={da}
              brOsovina={da}
              brBrzinaMjenjaca={da}
              boja={da}
              mjenjac={da}
              pogon={da}
              ostecenje={da}
              registracija={da}
              tablice={da}
            />
          </div>

          {nemaNalog && <LogoutDodajOglas />}
          <ButoniPretraga
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />
        </div>
      </div>
      <div className={kategorija === "Prikolica" ? "" : "hide"}>
        <div className="automobiliDodajOglasContainer">
          <Menu
            homeMenu="/icons/homee.svg"
            likedMenu="/icons/heart.png"
            addPostMenu="/icons/dodajOglass.png"
            porukeMenu="/icons/poruke.svg"
            profilMenu="/icons/podesavanja.svg"
          />
          <div className="atributi">
            <AutoFilter
              srcDodajOglasIkonica="/images/prikolicaSelect.png"
              kategorijaNaslovaDodajOglas="Prikolice"
              dodajOglas={da}
              showObaveznoPolje={da}
              tip={da}
              backDodajOglas={closeButtonDodajOglas}
              markaSelect={da}
              modelInput={da}
              stanje={da}
              boja={da}
              ostecenje={da}
              registracija={da}
              tablice={da}
              brOsovina={da}
              nosivost={da}
              obavezniPodatak={da}
              godisteDodajOglas={da}
              karoserija={da}
            />
          </div>
          <ButoniPretraga
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />
        </div>
      </div>
      <div className={kategorija === "Plovilo" ? "" : "hide"}>
        <div className="automobiliDodajOglasContainer">
          <Menu
            homeMenu="/icons/homee.svg"
            likedMenu="/icons/heart.png"
            addPostMenu="/icons/dodajOglass.png"
            porukeMenu="/icons/poruke.svg"
            profilMenu="/icons/podesavanja.svg"
          />
          <div className="atributi">
            <AutoFilter
              srcDodajOglasIkonica="/images/jahtaSelect.png"
              kategorijaNaslovaDodajOglas="Plovila"
              dodajOglas={da}
              showObaveznoPolje={da}
              markaSelect={da}
              modelInput={da}
              tip={da}
              obavezniPodatak={da}
              godisteDodajOglas={da}
              karoserija={da}
              duzina={da}
              sirina={da}
              gorivo={da}
              backDodajOglas={closeButtonDodajOglas}
              brSati={da}
              stanje={da}
              boja={da}
              materijalSelect={da}
              mjenjac={da}
              brMotora={da}
              ostecenje={da}
              registracija={da}
              tablice={da}
            />
          </div>

          {nemaNalog && <LogoutDodajOglas />}
          <ButoniPretraga
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />
        </div>
      </div>
      <div className={kategorija === "Autobus" ? "" : "hide"}>
        <div className="automobiliDodajOglasContainer">
          <div className="atributi">
            <AutoFilter
              dodajOglas={da}
              showObaveznoPolje={da}
              markaSelect={da}
              tip={da}
              modelInput={da}
              kmDodajOglas={da}
              srcDodajOglasIkonica="/images/busSelect.png"
              kategorijaNaslovaDodajOglas="Autobusi"
              ksDodajOglas={da}
              kwDodajOglas={da}
              cm3DodajOglas={da}
              obavezniPodatak={da}
              godisteDodajOglas={da}
              backDodajOglas={closeButtonDodajOglas}
              karoserija={da}
              gorivo={da}
              pogon={da}
              brBrzinaMjenjaca={da}
              stanje={da}
              boja={da}
              brVrata={da}
              brSjedista={da}
              mjenjac={da}
              ostecenje={da}
              registracija={da}
              tablice={da}
            />
          </div>

          {nemaNalog && <LogoutDodajOglas />}
          <ButoniPretraga
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />
        </div>
      </div>
      <div className={kategorija === "Masina" ? "" : "hide"}>
        <div className="automobiliDodajOglasContainer">
          {" "}
          <Menu
            homeMenu="/icons/homee.svg"
            likedMenu="/icons/heart.png"
            addPostMenu="/icons/dodajOglass.png"
            porukeMenu="/icons/poruke.svg"
            profilMenu="/icons/podesavanja.svg"
          />
          <div className="atributi">
            <AutoFilter
              srcDodajOglasIkonica="/images/masineSelect.png"
              kategorijaNaslovaDodajOglas="Građevinske mašine"
              dodajOglas={da}
              tip={da}
              showObaveznoPolje={da}
              markaSelect={da}
              modelInput={da}
              kwDodajOglas={da}
              cm3DodajOglas={da}
              obavezniPodatak={da}
              backDodajOglas={closeButtonDodajOglas}
              godisteDodajOglas={da}
              gorivo={da}
              stanje={da}
              pogon={da}
              boja={da}
              mjenjac={da}
              brSati={da}
              ostecenje={da}
              registracija={da}
              tablice={da}
            />
          </div>
          {nemaNalog && <LogoutDodajOglas />}
          <ButoniPretraga
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />
        </div>
      </div>
      <div className={kategorija === "Poljoprivreda" ? "" : "hide"}>
        <div className="automobiliDodajOglasContainer">
          {" "}
          <Menu
            homeMenu="/icons/homee.svg"
            likedMenu="/icons/heart.png"
            addPostMenu="/icons/dodajOglass.png"
            porukeMenu="/icons/poruke.svg"
            profilMenu="/icons/podesavanja.svg"
          />
          <div className="atributi">
            <AutoFilter
              srcDodajOglasIkonica="/images/traktorSelect.png"
              kategorijaNaslovaDodajOglas="Poljoprivredne mašine"
              dodajOglas={da}
              tip={da}
              showObaveznoPolje={da}
              markaSelect={da}
              modelInput={da}
              ksDodajOglas={da}
              kwDodajOglas={da}
              karoserija={da}
              brSati={da}
              cm3DodajOglas={da}
              obavezniPodatak={da}
              godisteDodajOglas={da}
              gorivo={da}
              stanje={da}
              backDodajOglas={closeButtonDodajOglas}
              tablice={da}
              registracija={da}
              pogon={da}
              brCilindara={da}
              boja={da}
              mjenjac={da}
              ostecenje={da}
            />
          </div>
          {nemaNalog && <LogoutDodajOglas />}
          <ButoniPretraga
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />
        </div>
      </div>
      <div className={kategorija === "Tocak" ? "" : "hide"}>
        <div className="automobiliDodajOglasContainer">
          <Menu
            homeMenu="/icons/homee.svg"
            likedMenu="/icons/heart.png"
            addPostMenu="/icons/dodajOglass.png"
            porukeMenu="/icons/poruke.svg"
            profilMenu="/icons/podesavanja.svg"
          />
          <div className="atributi">
            <AutoFilter
              kategorijeFelneGume={da}
              srcDodajOglasIkonica="/images/tocakSelect.png"
              kategorijaNaslovaDodajOglas="Felne / gume"
              dodajOglas={da}
              showObaveznoPolje={da}
              markaSelect={da}
              modelInput={da}
              backDodajOglas={closeButtonDodajOglas}
              obavezniPodatak={da}
              godisteDodajOglas={da}
              stanje={da}
              felne={da}
              gume={da}
              felneSaGumama={da}
            />
          </div>

          {nemaNalog && <LogoutDodajOglas />}
          <ButoniPretraga
            funkcijaButtona="Nastavi"
            buttoniContainer="buttoniContainer"
            detaljnaPretraga="detaljna-pretraga"
            pretraga="/izaberi-plan"
            stilovi="hide"
          />
        </div>
      </div>
    </div>
  );
}
