import React, { useState } from "react";
import "./izaberiPlan.css";
import { Link, useNavigate } from "react-router-dom";
import ButoniPretraga from "../../components/butoniPretraga/ButoniPretraga";
import Menu from "../../components/menu/Menu";
export default function IzaberiPlan() {
  const [checkedIndex, setCheckedIndex] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const handleCheckboxChange = (index) => {
    setCheckedIndex(index);
  };
  const handleSendMessage = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
      window.location.href = "/";
    }, 3000);
  };
  return (
    <div className="izaberiPlan">
      <Menu
        homeMenu="/icons/homee.svg"
        likedMenu="/icons/heart.png"
        addPostMenu="/icons/dodajOglass.png"
        porukeMenu="/icons/poruke.svg"
        profilMenu="/icons/podesavanja.svg"
      />

      <div className={showSuccessMessage ? "successMessage" : "hide"}>
        <div>
          <div>
            <img className="allDone" src="/images/allDone.png" />

            <img className="vector" src="/images/vector.png" />
          </div>
          <p>Uspješno ste poslali oglas!</p>
        </div>
      </div>

      <div className="headerAktivni">
        <Link className="back" to="/dodaj-oglas">
          <img src="/icons/back.png" className="backBtn" alt="Back" />
        </Link>
        <img className="logo" src="/icons/logo.png" />
        <h1 className="naslovAktivniOglasi">Izaberi plan</h1>
      </div>
      <p className="lastStep">3/3</p>
      <div className="izaberiPlanDivovi">
        <div className="freePlan" onClick={() => handleCheckboxChange(0)}>
          <div>
            <p className="cijenaFree">0 €</p>
            <p className="tekstFree">Klasično oglašavanje</p>
          </div>
          <div className="checkbox-wrapper-15">
            <input
              className="inp-cbx"
              id="cbx-15"
              type="checkbox"
              style={{ display: "none" }}
              checked={checkedIndex === 0}
              onChange={() => handleCheckboxChange(0)}
            />
            <label className="cbx" htmlFor="cbx-15">
              <span>
                <svg width="12px" height="9px" viewBox="0 0 12 9">
                  <polyline points="1 5 4 8 11 1"></polyline>
                </svg>
              </span>
            </label>
          </div>
        </div>
        <div
          className="payPlan promoPet"
          onClick={() => handleCheckboxChange(1)}
        >
          <div>
            <p className="cijenaPlan">5 €</p>
            <p className="tekstPay">Izražena vidljivost</p>
            <p className="tekstPay">Brža prodaja</p>
            <p className="tekstPay">Poseban naglasak</p>
            <p className="tekstPay">Istaknuta prisutnost</p>
            <p className="tekstPay">Trajanje 5 dana</p>
          </div>
          <div className="checkbox-wrapper-14">
            <input
              className="inp-cbx1"
              id="cbx-14"
              type="checkbox"
              style={{ display: "none" }}
              checked={checkedIndex === 1}
              onChange={() => handleCheckboxChange(1)}
            />
            <label className="cbx1" htmlFor="cbx-14">
              <span>
                <svg width="12px" height="9px" viewBox="0 0 12 9">
                  <polyline points="1 5 4 8 11 1"></polyline>
                </svg>
              </span>
            </label>
          </div>
        </div>
        <div
          className="payPlan promoDeset"
          onClick={() => handleCheckboxChange(2)}
        >
          <div>
            <p className="cijenaPlan">10 €</p>
            <p className="tekstPay">Izražena vidljivost</p>
            <p className="tekstPay">Brža prodaja</p>
            <p className="tekstPay">Poseban naglasak</p>
            <p className="tekstPay">Istaknuta prisutnost</p>
            <p className="tekstPay">Trajanje 10 dana</p>
          </div>
          <div className="checkbox-wrapper-13">
            <input
              className="inp-cbx2"
              id="cbx-13"
              type="checkbox"
              style={{ display: "none" }}
              checked={checkedIndex === 2}
              onChange={() => handleCheckboxChange(2)}
            />
            <label className="cbx2" htmlFor="cbx-13">
              <span>
                <svg width="12px" height="9px" viewBox="0 0 12 9">
                  <polyline points="1 5 4 8 11 1"></polyline>
                </svg>
              </span>
            </label>
          </div>
        </div>
      </div>
      <ButoniPretraga
        funkcijaButtona="Nastavi"
        buttoniContainer="buttoniContainer"
        detaljnaPretraga="detaljna-pretraga"
        pretraga={checkedIndex === 0 ? "/" : "/izaberi-plan/nacin-placanja"}
        stilovi="hide"
        onClick={checkedIndex === 0 ? handleSendMessage : null}
      />
    </div>
  );
}
